const tableHeaders = [
    'name',
    'location',
    'system size',
    'monthly payment',
    'member status',
    '',
]

const tableHeadersForDuePayment = [
    'name',
    'location',
    'email',
    'monthly payment',
    'member status',
]

const tableHeadersForCreditReport = [
    'name',
    'location',
    'email',
    'id card type',
    'credit report',
]

export const membersListValues = (
    pageAction,
    members,
    membersWithDuePayment,
    membersListFiltered,
    membersWithNoCreditReport,
) => {
    switch (pageAction) {
        case 'view-dashboard':
            return {
                list: members,
                tableHeaders: tableHeaders,
                isEmpty: members?.length === 0,
                emptyHeading: membersListFiltered
                    ? 'No results found. Try adjusting your filter or search'
                    : 'You have not added any member',
                emptyDescription: membersListFiltered
                    ? 'No matches found. Try broadening your filter or resetting it to see more options.'
                    : 'All members of this cluster will appear here, click on the add members button to get started.',
                rowClass: 'dashboardRow',
            }
        case 'make-payment':
            return {
                list: membersWithDuePayment,
                tableHeaders: tableHeadersForDuePayment,
                isEmpty: membersWithDuePayment?.length === 0,
                emptyHeading: 'There are no members with due payment',
                emptyDescription:
                    'Only members of your group members with due payment will appear here.',
                rowClass: 'paymentRow',
            }
        case 'upload-multiple-credit-report':
            return {
                list: membersWithNoCreditReport,
                tableHeaders: tableHeadersForCreditReport,
                isEmpty: membersWithNoCreditReport?.length === 0,
                emptyHeading: 'There are no members without credit reports',
                emptyDescription:
                    'Only members of your group members without credit reports will appear here.',
                rowClass: 'paymentRow',
            }
        default:
            return {
                list: [],
                tableHeaders: [],
                isEmpty: false,
                emptyHeading: '',
                emptyDescription: '',
                rowClass: 'dashboardRow',
            }
    }
}

export const dropdownMenuOptions = [
    {
        title: 'View member details',
        action: 'view-member-details',
        page: ['view-dashboard'],
    },
    {
        title: 'Resend system selection link',
        action: 'resend-link',
        page: ['view-dashboard'],
    },
    {
        title: 'Make payment',
        action: 'make-payment',
        page: ['view-dashboard'],
    },
    {
        title: 'Remove this member',
        action: 'remove-member',
        page: ['view-dashboard'],
    },
    {
        title: 'Upload credit report',
        action: 'upload-individual-credit-report',
        page: ['upload-multiple-credit-report', 'view-dashboard'],
    },
    {
        title: 'Remove this member',
        action: 'remove-credit-report-member',
        page: ['upload-multiple-credit-report'],
    },
]

export const creditReportFileName = (urls, member) => {
    if (!urls || !member) return null
    const creditReportExists = urls?.some(url => {
        return url?.name?.toLowerCase().includes(member?.email_address)
    })
    return creditReportExists
}
