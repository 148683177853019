import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import './admingroupsandpermissions.scss'
import { ReactComponent as BlueCloseIcon } from 'src/assets/images/blue-close-icon.svg'
import { ReactComponent as Close } from 'src/assets/images/close-icon.svg'
import Button from 'src/components/Button'
import ActionModal from 'src/components/ActionModal'
import AdminPermissionsMenu from './menu'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
    getChildProviderGroupResourcesApi,
    getParentProviderGroupResourcesApi,
    getPermissionsUnderResourceApi,
    updateProviderGroupApi,
} from 'src/api/admin/settings/providergroup'
import {
    getParentProviderWorkspaceResourcesApi,
    getChildProviderWorkspaceResourcesApi,
    getPermissionsUnderResourceProviderWorkspaceApi,
    addNewProviderWorkspaceApi,
} from 'src/api/providers/users/index'
import { Skeleton } from 'antd'
import { TableSkeletalCards } from 'src/components/SkeletalCards'
import { errorHandler } from 'src/utils/errorHandler'
import { InlineLoader } from 'src/components/Loader'
import { addNewGroupApi } from 'src/api/admin/user/addGroupandPermissions'
import { updateProvidersGroupApi } from 'src/api/providers/users/groups'

const AddAdminGroupPermissions = ({
    setStep,
    module,
    id,
    persistedPermissions,
    selectedPermissions,
    setSelectedPermissions,
    groupFetching,
    setError,
    setErrorMessage,
    selectedPermissionIds,
    setSelectedPermissionIds,
    redirectToSettings,
    groupName,
    type,
    isProviderWorkspace = false,
}) => {
    const [showModal, setShowModal] = useState(false)
    const [allPermissions, setAllPermissions] = useState([])
    const [parentResource, setParentResource] = useState({})
    const [childResource, setChildResource] = useState({})
    const [sendPayload, setSendPayload] = useState(false)
    //ids passed into API call to either be added or removed
    const [addPermissionsIds, setAddPermissionIds] = useState([])
    const [removePermissionsIds, setRemovePermissionIds] = useState([])
    const isProvider = type === 'provider-user'
    const history = useHistory()

    const {
        refetch: parentResourcesRefetch,
        isFetching: parentResourcesFetching,
    } = useQuery(
        'fetch-parent-resources',
        () =>
            isProviderWorkspace || isProvider
                ? getParentProviderWorkspaceResourcesApi()
                : getParentProviderGroupResourcesApi(type),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const fetched = data?.data?.data
                setAllPermissions([])
                for (let i = 0; i < fetched?.length; i++) {
                    if (fetched[i].has_children) {
                        setAllPermissions(prev => [
                            ...prev,
                            {
                                name: fetched[i].name,
                                id: fetched[i].id,
                            },
                        ])
                    }
                }
            },
            onError: error => {
                setError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const { refetch: childResourcesRefetch } = useQuery(
        'fetch-child-resources',
        () =>
            isProviderWorkspace || isProvider
                ? getChildProviderWorkspaceResourcesApi(parentResource?.id)
                : getChildProviderGroupResourcesApi(parentResource?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const fetched = data?.data?.data
                let newObjs = []
                const updatedArr = [...allPermissions]
                for (let i = 0; i < fetched?.length; i++) {
                    newObjs.push({
                        name: fetched[i].name,
                        id: fetched[i].id,
                    })
                }
                const item = updatedArr.find(
                    i => i.name === parentResource.name,
                )
                if (item) {
                    item.children = newObjs
                }
                setAllPermissions(updatedArr)
                setParentResource({})
            },
            onError: error => {
                setError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const { refetch: permissionsRefetch } = useQuery(
        'fetch-resource-permissions',
        () =>
            isProviderWorkspace || isProvider
                ? getPermissionsUnderResourceProviderWorkspaceApi(
                      childResource?.id,
                  )
                : getPermissionsUnderResourceApi(childResource?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const fetched = data?.data?.data
                let newObjs = []
                //get all objects under fetched where id === childResource.id and save it in newObjs
                for (let i = 0; i < fetched?.length; i++) {
                    if (fetched[i].resource.id === childResource?.id) {
                        newObjs.push({
                            name: fetched[i].name,
                            id: fetched[i].id,
                        })
                    }
                }
                //get object with id === childResource.id and add newObjs to permissions array of that object
                const updatedArr = [...allPermissions]
                const item = updatedArr.find(resource => {
                    if (resource.children) {
                        return resource.children.find(
                            i => i.id === childResource.id,
                        )
                    }
                })
                if (item) {
                    item.children.find(
                        i => i.id === childResource.id,
                    ).permissions = newObjs
                }
                setAllPermissions(updatedArr)
                setChildResource({})
            },
            onError: error => {
                setError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )
    const {
        refetch: updatePermissionsRefetch,
        isLoading: updatePermissionsLoading,
    } = useQuery(
        'update-provider-group-permissions',
        () =>
            isProvider
                ? updateProvidersGroupApi(id, {
                      add_permission_ids: addPermissionsIds,
                      remove_permission_ids: removePermissionsIds,
                      workspace: 'PROVIDER',
                  })
                : updateProviderGroupApi(id, {
                      add_permission_ids: addPermissionsIds,
                      remove_permission_ids: removePermissionsIds,
                      workspace: type === 'admin' ? 'ADMIN' : 'PROVIDER',
                  }),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setShowModal(true)
                setSendPayload(false)
                setAddPermissionIds([])
                setRemovePermissionIds([])
            },
            onError: error => {
                setError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const createGroupPayload = {
        name: groupName,
        workspace: type === 'admin' ? 'ADMIN' : 'PROVIDER',
        permission_ids: selectedPermissionIds,
    }

    const { refetch: addGroupRefetch, isLoading: addGroupLoading } = useQuery(
        'add-new-group',
        () =>
            isProviderWorkspace
                ? addNewProviderWorkspaceApi(createGroupPayload)
                : addNewGroupApi(createGroupPayload),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setShowModal(true)
            },
            onError: error => {
                setError(true)
                setErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        parentResourcesRefetch()
    }, [parentResourcesRefetch])

    const fetchChildResources = parent => {
        setParentResource(parent)
    }

    useEffect(() => {
        if (Object.keys(parentResource)?.length !== 0) {
            childResourcesRefetch()
        }
    }, [childResourcesRefetch, parentResource])

    const fetchPermissions = child => {
        setChildResource(child)
    }

    useEffect(() => {
        if (Object.keys(childResource)?.length !== 0) {
            permissionsRefetch()
        }
    }, [permissionsRefetch, childResource])

    //remove permission from selectedPermissions and selectedPermissionIds
    const removePermissionFromState = resourceId => {
        const updated = selectedPermissions.filter(i => i.id !== resourceId)
        setSelectedPermissions(updated)
        setSelectedPermissionIds(prev => {
            if (prev.includes(resourceId)) {
                return prev.filter(i => i !== resourceId)
            } else {
                return prev
            }
        })
    }

    const handlePermissionClick = (
        parentName,
        childName,
        permissionName,
        resourceId,
        action,
        event,
    ) => {
        event.stopPropagation()
        if (action === 'add') {
            setSelectedPermissions(prev => [
                ...prev,
                {
                    id: resourceId,
                    tree: [parentName, childName, permissionName],
                },
            ])
            setSelectedPermissionIds(prev => {
                if (prev.includes(resourceId)) {
                    return prev
                } else {
                    return [...prev, resourceId]
                }
            })
        }
        if (action === 'remove') {
            removePermissionFromState(resourceId)
        }
    }

    //select all permissions under a child resource if they don't exist in state
    const handleSelectAll = (parentName, childName, childPermissions) => {
        const updated = [...selectedPermissions]
        const updatedIds = [...selectedPermissionIds]
        for (let i = 0; i < childPermissions?.length; i++) {
            if (!updatedIds.includes(childPermissions[i].id)) {
                updated.push({
                    id: childPermissions[i].id,
                    tree: [parentName, childName, childPermissions[i].name],
                })
                updatedIds.push(childPermissions[i].id)
            }
        }
        setSelectedPermissions(updated)
        setSelectedPermissionIds(updatedIds)
    }

    const resetSelection = childName => {
        const updated = [...selectedPermissions]
        const permissionsAfterResetSelection = updated.filter(
            item => item.tree[1] !== childName,
        )
        const permissionsAfterResetSelectionIds = updated
            .filter(item => item.tree[1] !== childName)
            .map(item => item.id)
        setSelectedPermissions([...permissionsAfterResetSelection])
        setSelectedPermissionIds([...permissionsAfterResetSelectionIds])
    }

    //deselect all permissions under a child resource
    const removeAllPermissions = () => {
        setSelectedPermissions([])
        setSelectedPermissionIds([])
    }

    //go to previous step
    const handlePreviousClick = () => {
        setStep(1)
    }

    //continue group creation
    const handleContinueClick = () => {
        //if id exists in persistedPermissions but not in selectedPermissionIds, add it to removePermissionsIds
        const removeIds = persistedPermissions.filter(
            i => !selectedPermissionIds.includes(i.id),
        )
        setRemovePermissionIds(removeIds.map(i => i.id))

        //if id exists in selectedPermissions but not in persistedPermissions, add it to addPermissionsIds
        const persistedPermissionIds = persistedPermissions.map(i => i.id)
        const addIds = selectedPermissions.filter(
            i => !persistedPermissionIds.includes(i.id),
        )
        setAddPermissionIds(addIds.map(i => i.id))
        setSendPayload(true)
    }

    const addGroupContinueBtn = () => {
        addGroupRefetch()
    }

    useEffect(() => {
        if (
            (addPermissionsIds.length !== 0 ||
                removePermissionsIds?.length !== 0) &&
            sendPayload === true
        ) {
            updatePermissionsRefetch()
        }
    }, [
        addPermissionsIds?.length,
        removePermissionsIds?.length,
        sendPayload,
        updatePermissionsRefetch,
    ])

    //after success modal is shown, close modal and redirect user
    const handleAfterSuccess = () => {
        setShowModal(false)
        if (module === 'existing-provider-group' && isProvider) {
            history.push(`/app/group/${id}`)
        }

        if (module === 'existing-provider-group' && !isProvider) {
            history.push(`/admin/settings/provider-groups/${id}`)
        }
        if (module === 'existing-admin-group') {
            history.push(`/admin/group/${id}`)
        }
        if (module === 'add-new-group') {
            if (isProviderWorkspace) {
                window.location.href = '/app/users/listing'
            } else if (redirectToSettings) {
                window.location.href = '/admin/settings?tab=2'
            } else {
                window.location.href = '/admin/users/listing'
            }
        }
    }

    const successTitleMapping = {
        'existing-provider-group': 'Group Updated Successfully',
        'existing-admin-group': 'Group Updated Successfully',
        'add-new-group': 'Group Created Successfully',
    }

    const fetching = groupFetching || parentResourcesFetching

    return (
        <>
            {/*success modal*/}
            <ActionModal
                actionModalOpen={showModal}
                headerText={successTitleMapping[module]}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionType="success"
                actionText="Okay, Got it"
                noCancelBtn={true}
                actionHandler={handleAfterSuccess}
                closable
                onCancel={handleAfterSuccess}
                noBackLink
            />

            {/*section content*/}
            <div className="AGAPAddPermissionsWrapper">
                <div className="AGAPMenuAndRemoveWrapper">
                    {fetching ? (
                        <Skeleton.Input
                            style={{ width: '100%', height: '60px' }}
                            active={true}
                        />
                    ) : (
                        <>
                            <AdminPermissionsMenu
                                allPermissions={allPermissions}
                                fetchChildResources={fetchChildResources}
                                fetchPermissions={fetchPermissions}
                                selectedPermissionIds={selectedPermissionIds}
                                handlePermissionClick={handlePermissionClick}
                                parentResource={parentResource}
                                childResource={childResource}
                                handleSelectAll={handleSelectAll}
                                resetSelection={resetSelection}
                            />

                            {selectedPermissions?.length > 0 && (
                                <div
                                    className="AGAPCloseTextButton"
                                    onClick={() => removeAllPermissions()}
                                >
                                    <BlueCloseIcon />
                                    <p>Remove all</p>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {fetching ? (
                    <TableSkeletalCards total={0} />
                ) : (
                    <div className="AGAPSelectedWrapper">
                        {selectedPermissions?.map((each, i) => {
                            return (
                                <div className="AGAPSelectedItem" key={i}>
                                    <p>
                                        <span>{each?.tree?.[0]} </span>{' '}
                                        {each?.tree?.[1] && (
                                            <span>
                                                {' '}
                                                &gt;&gt; {each?.tree?.[1]}
                                            </span>
                                        )}{' '}
                                        {each?.tree?.[2] && (
                                            <span>
                                                &gt;&gt; {each?.tree?.[2]}
                                            </span>
                                        )}
                                    </p>
                                    <div
                                        onClick={() =>
                                            removePermissionFromState(each?.id)
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Close />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}

                <div className="AGAPButtonsWrapper">
                    {module !== 'existing-provider-group' &&
                        module !== 'existing-admin-group' && (
                            <Button
                                btnWidth="132px"
                                btnHeight="50px"
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                fontSize="14px"
                                fontFamily="SF-Pro-Display-Semibold"
                                handleClick={handlePreviousClick}
                            >
                                Previous
                            </Button>
                        )}
                    <Button
                        btnWidth="132px"
                        btnHeight="50px"
                        btnBgColor="#004AAD"
                        btnTextColor="#FFFFFF"
                        fontSize="14px"
                        fontFamily="SF-Pro-Display-Semibold"
                        handleClick={
                            module === 'add-new-group'
                                ? addGroupContinueBtn
                                : handleContinueClick
                        }
                        disabled={selectedPermissions?.length < 1}
                    >
                        {updatePermissionsLoading || addGroupLoading ? (
                            <InlineLoader />
                        ) : (
                            'Continue'
                        )}
                    </Button>
                </div>
            </div>
        </>
    )
}

AddAdminGroupPermissions.propTypes = {
    setStep: PropTypes.func,
    permissions: PropTypes.array,
    handlePermissions: PropTypes.func,
    clearPermissions: PropTypes.func,
    module: PropTypes.string,
    id: PropTypes.string,
    persistedPermissions: PropTypes.array,
    selectedPermissions: PropTypes.array,
    setSelectedPermissions: PropTypes.func,
    selectedPermissionIds: PropTypes.array,
    setSelectedPermissionIds: PropTypes.func,
    groupFetching: PropTypes.bool,
    setError: PropTypes.func,
    setErrorMessage: PropTypes.func,
    redirectToSettings: PropTypes.any,
    groupName: PropTypes.string,
    type: PropTypes.string,
    isProviderWorkspace: PropTypes.bool,
}

export default AddAdminGroupPermissions
