import PropTypes from 'prop-types'
import ConsumerModalBase from 'src/components/Modal/ConsumerModalBase'
import Options from './components/Options'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import useAddMembersModal from './useAddMembersModal'
import PersonalInfo from './components/PersonalInfo'
import IdInfo from './components/IdInfo'
import React from 'react'

const AddMembersModal = ({ showModal, onCancel, handleHoldNewMember }) => {
    const {
        method,
        action,
        handleSelectMethod,
        handleClick,
        standardHeight,
        closeModal,
        modalTitle,
        inputs,
        errors,
        handleChange,
        handleOnBlur,
        handleSelectChange,
        handleSelectOnBlur,
    } = useAddMembersModal(onCancel, handleHoldNewMember)
    const { isMobile } = useMediaQueries()

    return (
        <ConsumerModalBase
            showModal={showModal}
            onCancel={closeModal}
            standardWidth={477}
            standardHeight={standardHeight}
            title={modalTitle}
            primaryBtnText={'Continue'}
            primaryBtnDisabled={method === ''}
            primaryBtnWidth={isMobile ? '127px' : '160px'}
            handlePrimaryBtnClick={handleClick}
        >
            <React.Fragment id="Rep_AddMembersContainer">
                {action === 'select-method' ? (
                    <Options
                        method={method}
                        handleSelectMethod={handleSelectMethod}
                    />
                ) : action === 'single-member-personal-info' ? (
                    <PersonalInfo
                        inputs={inputs}
                        errors={errors}
                        handleChange={handleChange}
                        handleOnBlur={handleOnBlur}
                    />
                ) : action === 'single-member-id-info' ? (
                    <IdInfo
                        inputs={inputs}
                        errors={errors}
                        handleChange={handleChange}
                        handleOnBlur={handleOnBlur}
                        handleSelectChange={handleSelectChange}
                        handleSelectOnBlur={handleSelectOnBlur}
                    />
                ) : (
                    <div>not set yet</div>
                )}
            </React.Fragment>
        </ConsumerModalBase>
    )
}

AddMembersModal.propTypes = {
    showModal: PropTypes.bool,
    onCancel: PropTypes.func,
    handleHoldNewMember: PropTypes.func,
}

export default AddMembersModal
