import { useState } from 'react'
import { formatInputValue, formatPhoneNumber } from 'src/utils/formatting'
import useValidateOnBlur from 'src/utils/Hooks/useValidateOnBlur'
import { validateClusterMemberInfo } from 'src/utils/validationSchema'
import { scrollToById } from 'src/utils/scrollToById'

const useAddMembersModal = (onCancel, handleHoldNewMember) => {
    const [method, setMethod] = useState('') // single | multiple
    const [action, setAction] = useState('select-method') // select-method | single-member-personal-info | 'single-member-id-info'
    const [standardHeight, setStandardHeight] = useState(523)
    const [modalTitle, setModalTitle] = useState(
        'Add all members in your cluster',
    )
    const inputsValue = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        idCardType: '',
        idCardNo: '',
    }
    const [inputs, setInputs] = useState(inputsValue)
    const [errors, setErrors] = useState({})
    const validateField = useValidateOnBlur()

    const schemaContext = {
        personalInfoForm: action === 'single-member-personal-info',
        idInfoForm: action === 'single-member-id-info',
        idCardType: inputs?.idCardType,
    }

    const handleSelectMethod = key => {
        setMethod(key)
    }

    const closeModal = () => {
        setAction('select-method')
        setMethod('')
        setStandardHeight(523)
        setModalTitle('Add all members in your cluster')
        setInputs(inputsValue)
        onCancel()
    }

    const handleSingleMemberInfoClick = () => {
        validateClusterMemberInfo
            .validate(inputs, {
                context: schemaContext,
                abortEarly: false,
            })
            .then(() => {
                if (action === 'single-member-personal-info') {
                    setAction('single-member-id-info')
                    setStandardHeight(430)
                }
                if (action === 'single-member-id-info') {
                    const { idCardNo, idCardType, ...allInputs } = inputs
                    handleHoldNewMember({
                        ...allInputs,
                        phone_number: formatPhoneNumber(allInputs.phone_number),
                        number: idCardNo,
                        type: idCardType,
                    })
                    closeModal()
                }
                scrollToById('Rep_AddMembersContainer')
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleClick = () => {
        switch (action) {
            case 'select-method':
                if (method === 'single') {
                    setAction('single-member-personal-info')
                    setStandardHeight(625)
                    setModalTitle('Add single members')
                }
                break
            case 'single-member-personal-info':
                handleSingleMemberInfoClick()
                break
            case 'single-member-id-info':
                handleSingleMemberInfoClick()
                break
        }
    }

    const removeErrors = name => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[name]
            return newErrors
        })
    }

    const handleOnBlur = async e => {
        const { name, value } = e.target

        await validateField({
            name,
            value,
            schema: validateClusterMemberInfo,
            setErrors,
            context: schemaContext,
        })
    }

    const handleSelectOnBlur = async name => {
        await validateField({
            name,
            value: inputs[`${name}`],
            schema: validateClusterMemberInfo,
            setErrors,
            context: schemaContext,
        })
    }

    const handleChange = e => {
        const { name, value } = e.target

        setInputs(prev => ({
            ...prev,
            [name]: formatInputValue(name, value),
        }))
        removeErrors(name)
    }

    const handleSelectChange = (name, value) => {
        setInputs(prev => ({
            ...prev,
            [name]: value,
        }))
        removeErrors(name)
    }

    return {
        method,
        action,
        handleSelectMethod,
        handleClick,
        standardHeight,
        closeModal,
        modalTitle,
        inputs,
        errors,
        handleChange,
        handleOnBlur,
        handleSelectChange,
        handleSelectOnBlur,
    }
}

export default useAddMembersModal
