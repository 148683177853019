import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import Label from '../../../../PersonalInformation/_components/Label'
import { InputFields, SelectField } from 'src/components/InputFields'
import { sharedFormComponentPropTypes } from '../utils'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import {
    countries,
    sameAddressOptions,
} from '../../../../OutrightSaleInformation/utils'
import { localGovtData, stateData } from 'src/utils/stateData'
import { SelectButtons } from 'src/components/SelectButtons'

const Address = ({
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    handleOnBlur,
    handleSelectOnBlur,
    handleSameAddressOptionChange,
    isAdminUser,
    isCustomerApproved,
}) => {
    return (
        <>
            <Label
                title={'Your contact address'}
                subTitle={
                    'Please provide your contact address. This does not have to be the same as your installation address'
                }
            />
            <InputFields
                name="contactAddress"
                title="House no. and street address"
                handleChange={handleChange}
                onBlur={handleOnBlur}
                value={inputs?.contactAddress}
                errorMessage={errors?.contactAddress}
                disabled={isAdminUser || isCustomerApproved}
            />

            <div className="PersonalInfo_DualSelectField-Wrapper">
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactCountry"
                    floatingLabel="Country"
                    initialOption="Country"
                    selectWidth="100%"
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactCountry')}
                    values={countries.map(option => ({
                        value: option,
                    }))}
                    value={inputs?.contactCountry}
                    errorMessage={errors?.contactCountry}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactCountry}
                    selectedValue={inputs?.contactCountry}
                    disabled={isAdminUser || isCustomerApproved}
                />
                <SelectField
                    floatLabelOnSelectedValue
                    name="contactState"
                    floatingLabel="State"
                    initialOption="State"
                    selectWidth="100%"
                    values={stateData?.map(option => ({
                        value: option,
                    }))}
                    value={inputs.contactState}
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactState')}
                    errorMessage={errors?.contactState}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactState}
                    selectedValue={inputs?.contactState}
                    disabled={isAdminUser || isCustomerApproved}
                />

                <SelectField
                    floatLabelOnSelectedValue
                    name="contactCity"
                    floatingLabel="L.G.A."
                    initialOption="L.G.A."
                    selectWidth="100%"
                    values={localGovtData[inputs?.contactState || 'Lagos']?.map(
                        option => ({
                            value: option,
                        }),
                    )}
                    disabled={
                        inputs?.contactState?.length < 1 ||
                        isAdminUser ||
                        isCustomerApproved
                    }
                    value={inputs.contactCity}
                    handleChange={handleSelectChange}
                    onBlur={() => handleSelectOnBlur('contactCity')}
                    errorMessage={errors?.contactCity}
                    showSearch
                    dropdownPositionRelative
                    withCheckBox
                    currentSelected={inputs?.contactCity}
                    selectedValue={inputs?.contactCity}
                />
            </div>

            <div className="ContactAddress_Confirmation-Wrapper">
                <p>
                    Is your contact address the same as where you want to
                    install the system?
                </p>
                <SelectButtons
                    selectedValue={inputs.sameAddress.title}
                    values={sameAddressOptions?.map(o => o.title)}
                    handleChange={value => handleSameAddressOptionChange(value)}
                    width={163}
                    height={46}
                    disabled={isAdminUser || isCustomerApproved}
                />
                {errors?.confirmAddress && (
                    <span className="fieldError">{errors?.confirmAddress}</span>
                )}
            </div>

            <VerticalSpacer top={16} />
            {!inputs?.sameAddress?.value && (
                <>
                    <Label
                        title="Your installation address"
                        subTitle="Please provide us with where you want to have the inverter installed."
                    />
                    <InputFields
                        name="installationAddress"
                        title="House no. and street address"
                        handleChange={handleChange}
                        onBlur={handleOnBlur}
                        value={inputs?.installationAddress}
                        errorMessage={errors?.installationAddress}
                        disabled={isAdminUser || isCustomerApproved}
                    />

                    <div className="PersonalInfo_DualSelectField-Wrapper">
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationCountry"
                            floatingLabel="Country"
                            initialOption="Country"
                            selectWidth="100%"
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationCountry')
                            }
                            values={countries.map(option => ({
                                value: option,
                            }))}
                            value={inputs?.installationCountry}
                            errorMessage={errors?.installationCountry}
                            showSearch
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={inputs?.installationCountry}
                            selectedValue={inputs?.installationCountry}
                            disabled={isAdminUser || isCustomerApproved}
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationState"
                            floatingLabel="State"
                            initialOption="State"
                            selectWidth="100%"
                            values={stateData?.map(option => ({
                                value: option,
                            }))}
                            value={inputs.installationState}
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationState')
                            }
                            errorMessage={errors?.installationState}
                            showSearch
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={inputs?.installationState}
                            selectedValue={inputs?.installationState}
                            disabled={isAdminUser || isCustomerApproved}
                        />
                        <SelectField
                            floatLabelOnSelectedValue
                            name="installationCity"
                            floatingLabel="L.G.A."
                            initialOption="L.G.A."
                            selectWidth="100%"
                            values={localGovtData[
                                inputs?.installationState || 'Lagos'
                            ]?.map(option => ({
                                value: option,
                            }))}
                            value={inputs.installationCity}
                            handleChange={handleSelectChange}
                            onBlur={() =>
                                handleSelectOnBlur('installationCity')
                            }
                            errorMessage={errors?.installationCity}
                            showSearch
                            dropdownPositionRelative
                            withCheckBox
                            currentSelected={inputs?.installationCity}
                            selectedValue={inputs?.installationCity}
                            disabled={
                                inputs?.installationState?.length < 1 ||
                                isAdminUser ||
                                isCustomerApproved
                            }
                        />
                    </div>
                    <VerticalSpacer top={12} />
                </>
            )}
        </>
    )
}

Address.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default Address
