import { useDispatch } from 'react-redux'
import { resetAdminCreditSystemFilters } from 'src/redux/reducers/admin/credit-system/listing'
import { resetAdminDTCReviewFilters } from 'src/redux/reducers/admin/dtc-review/listing'
import { resetAdminOutrightSaleFilters } from 'src/redux/reducers/admin/outright-sale/listing'
import { resetAdminPaymentPlanFilters } from 'src/redux/reducers/admin/payment-plan/listing'
import { resetAdminProductsFilters } from 'src/redux/reducers/admin/products/listing'
import { resetAdminProfilesFilters } from 'src/redux/reducers/admin/profiles/listing'
import { resetAdminSettingsFilters } from 'src/redux/reducers/admin/settings/listing'
import { resetAdminUsersFilters } from 'src/redux/reducers/admin/users/listing'
import { resetProviderCustomersFilters } from 'src/redux/reducers/provider/customers/listing'
import { resetProviderPaymentPlanFilters } from 'src/redux/reducers/provider/payment-plan/listing'
import { resetProviderProductsFilters } from 'src/redux/reducers/provider/products/listing'
import { decodeUserInfo } from './auth'

export const useClearPersistedFilter = () => {
    const dispatch = useDispatch()
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'

    const clearPersistedFilter = url => {
        switch (url) {
            case '/admin/plans':
                dispatch(resetAdminPaymentPlanFilters())
                break
            case '/app/payment-plans':
                dispatch(resetProviderPaymentPlanFilters())
                break
            case '/app/customers':
                dispatch(resetProviderCustomersFilters())
                break
            case '/admin/products':
                dispatch(resetAdminProductsFilters())
                break
            case '/admin/ouright-sale':
                dispatch(resetAdminOutrightSaleFilters())
                break
            case '/admin/alternative-recommendations':
                dispatch(resetAdminDTCReviewFilters())
                break
            case '/admin/credit':
                dispatch(resetAdminCreditSystemFilters())
                break
            case '/admin/users/listings':
                dispatch(resetAdminUsersFilters())
                break
            case '/app/users/listing':
                dispatch(resetAdminUsersFilters())
                break
            case '/admin/settings':
                dispatch(resetAdminSettingsFilters())
                break
            case '/admin/consumer-profiles':
                dispatch(resetAdminProfilesFilters())
                break
            case '/app/products':
                isAdminUser && dispatch(resetAdminProductsFilters())
                dispatch(resetProviderProductsFilters())
                break
            default:
        }
    }

    return { clearPersistedFilter }
}
