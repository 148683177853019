import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import { SelectField } from 'src/components/InputFields'
import { repaymentMethods } from 'src/utils/repaymentMethods'

const RepaymentMethod = ({
    inputs,
    errors,
    handleSelectChange,
    handleSelectOnBlur,
    isAdminUser,
    isCustomerApproved,
}) => {
    return (
        <>
            <Label
                title={
                    isAdminUser
                        ? `Customer's Preferred Repayment Method`
                        : 'Preferred Payment method'
                }
                subTitle={
                    !isAdminUser &&
                    'Select how best you’ll like to make your payment'
                }
            />
            <SelectField
                floatLabelOnSelectedValue
                floatingLabel="Select repayment method"
                name="repaymentMethod"
                initialOption="Select repayment method"
                selectWidth="100%"
                marginBottom="24px"
                values={repaymentMethods.map(option => ({
                    value: option,
                }))}
                value={inputs.repaymentMethod}
                errorMessage={errors?.repaymentMethod}
                handleChange={handleSelectChange}
                onBlur={() => handleSelectOnBlur('repaymentMethod')}
                disabled={isAdminUser || isCustomerApproved}
                withCheckBox
                selectedValue={inputs.repaymentMethod}
                currentSelected={inputs.repaymentMethod}
                dropdownPositionRelative
            />
        </>
    )
}

RepaymentMethod.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default RepaymentMethod
