import { ReactComponent as User } from 'src/assets/images/user-no-stroke.svg'
import { ReactComponent as Users } from 'src/assets/images/users-thick-no-stroke.svg'

export const addMembersMethods = [
    {
        title: 'Add a single member',
        description: 'Add one member to your group',
        key: 'single',
        icon: <User />,
    },
    {
        title: 'Add multiple group members',
        description: 'Add more than one member at a time',
        key: 'multiple',
        icon: <Users />,
    },
]
