import residenceIcon from 'src/assets/images/residence-icon.svg'
import businessIcon from 'src/assets/images/business-icon.svg'
import residenceIconNew from 'src/assets/images/residence-icon-new.png'
import businessIconNew from 'src/assets/images/business-icon-new.png'

export const useTypeDetails = [
    {
        icon: residenceIcon,
        title: 'Home or Stalls/Shops',
        description:
            'Suitable for remote workers, shop owners, residential homes and apartments.',
        value: 'RESIDENTIAL',
    },
    {
        icon: businessIcon,
        title: 'Registered Business',
        description:
            'Suitable for NGOs, LLCs, or large businesses with CAC registration.',
        value: 'BUSINESS',
    },
]

export const useTypeDetailsNew = [
    {
        icon: residenceIconNew,
        title: 'Home or Stalls/Shops',
        description:
            'Suitable for remote workers, shop owners, residential homes and apartments.',
        value: 'RESIDENTIAL',
    },
    {
        icon: businessIconNew,
        title: 'Registered Business',
        description:
            'Suitable for NGOs, LLCs, or large businesses with CAC registration.',
        value: 'BUSINESS',
    },
]

export const urgencyOptions = [
    'Not sure - exploring options',
    'At some point this year',
    'Within the next month or so',
    'Within the next 2 weeks',
    'ASAP, within the next few days',
]

export const urgencyOptionsMap = {
    [urgencyOptions[0]]: 'NOT_SURE',
    [urgencyOptions[1]]: 'THIS_YEAR',
    [urgencyOptions[2]]: 'NEXT_MONTH',
    [urgencyOptions[3]]: 'IN_2_WEEKS',
    [urgencyOptions[4]]: 'IN_FEW_DAYS',
}

export const heardAboutUsOptions = [
    'Google Search',
    'Family/friend',
    'Facebook',
    'Instagram',
    'Twitter',
    'LinkedIn',
    'Bill board',
    'Fliers',
    'Supermarket',
    'Another energy provider',
    'Stickers on SunFi systems',
]
