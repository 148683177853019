import PropTypes from 'prop-types'
import '../consumermodalbase.scss'
import Header from './Header'
import Footer from './Footer'
import { footerPropTypes, mobileModalPropTypes } from '../utils'

const MobileModal = ({
    showModal,
    onCancel,
    mobileHeight,
    children,
    title,
    footerLeftContent,
    chatCTAText,
    secondaryBtnWidth,
    secondaryBtnHeight,
    handleSecondaryBtnClick,
    secondaryBtnDisabled,
    secondaryBtnLoading,
    secondaryBtnText,
    primaryBtnWidth,
    primaryBtnHeight,
    handlePrimaryBtnClick,
    primaryBtnDisabled,
    primaryBtnLoading,
    primaryBtnText,
    footerClassName,
}) => {
    return (
        <div
            className={`consumer-modal-base-mobile-backdrop ${
                showModal ? 'open' : ''
            }`}
            onClick={onCancel}
        >
            <div
                className="consumer-modal-base-mobile-content"
                style={{ height: mobileHeight }}
                onClick={e => e.stopPropagation()}
            >
                <Header title={title} onCancel={onCancel} />
                <div className="consumer-modal-base-main">{children}</div>
                <Footer
                    footerLeftContent={footerLeftContent}
                    chatCTAText={chatCTAText}
                    secondaryBtnWidth={secondaryBtnWidth}
                    secondaryBtnHeight={secondaryBtnHeight}
                    handleSecondaryBtnClick={handleSecondaryBtnClick}
                    secondaryBtnDisabled={secondaryBtnDisabled}
                    secondaryBtnLoading={secondaryBtnLoading}
                    secondaryBtnText={secondaryBtnText}
                    primaryBtnWidth={primaryBtnWidth}
                    primaryBtnHeight={primaryBtnHeight}
                    handlePrimaryBtnClick={handlePrimaryBtnClick}
                    primaryBtnDisabled={primaryBtnDisabled}
                    primaryBtnLoading={primaryBtnLoading}
                    primaryBtnText={primaryBtnText}
                    footerClassName={footerClassName}
                />
            </div>
        </div>
    )
}

MobileModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
    ...footerPropTypes,
    ...mobileModalPropTypes,
}

export default MobileModal
