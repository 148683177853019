import PropTypes from 'prop-types'
import { AppButton } from 'src/components/Button'
import styles from '../providerGroups.module.scss'
import EmptyState from 'src/components/EmptyState'

const PermissionsTable = ({ openModal, data }) => {
    const {
        TableHead,
        TableRow,
        DesktopTable,
        MobileCards,
        Card,
        Top,
        Bottom,
        TitleAndText,
    } = styles
    return (
        <div>
            {data?.length === 0 ? (
                <EmptyState />
            ) : (
                <>
                <div className={DesktopTable}>
                    <div className={TableHead}>
                        <p>Parent Name</p>
                        <p>Permissions</p>
                        <p>Action</p>
                    </div>
                    <div>
                        {data.map((each, i) => (
                            <div key={i} className={TableRow}>
                                <h4>{each?.parent_resource?.name}</h4>
                                <p>{each?.permission_count}</p>
                                <AppButton
                                    btnBgColor="#E2EEFF"
                                    btnTextColor="#004AAD"
                                    btnWidth="70px"
                                    btnHeight="37px"
                                    fontFamily="SF-Pro-Display-Medium"
                                    fontSize="12px"
                                    handleClick={() =>
                                        openModal(each?.parent_resource?.id)
                                    }
                                >
                                    View
                                </AppButton>
                            </div>
                        ))}
                    </div>
                </div>
                    <div className={MobileCards}>
                        {/*    card to be mapped*/}
                        {data.map((each, i) => (
                        <div className={Card} key={i}>
                            <div className={Top}>
                                <p className={TitleAndText}>
                                    Payment Name
                                    <br />
                                    <span>{each?.parent_resource?.name}</span>
                                </p>
                                <AppButton
                                    btnBgColor="#E2EEFF"
                                    btnTextColor="#004AAD"
                                    btnWidth="56px"
                                    btnHeight="37px"
                                    fontFamily="SF-Pro-Display-Medium"
                                    fontSize="12px"
                                    handleClick={() =>
                                        openModal(each?.parent_resource?.id)
                                    }
                                >
                                    View
                                </AppButton>
                            </div>
                            <div className={Bottom}>
                                <p className={TitleAndText}>
                                    Permissions
                                    <br />
                                    <span>{each?.permission_count}</span>
                                </p>
                            </div>
                        </div>
                        ))}

                    </div>

                </>
            )}
        </div>
    )
}

PermissionsTable.propTypes = {
    openModal: PropTypes.func,
    data: PropTypes.array,
}

export default PermissionsTable
