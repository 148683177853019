import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import DetailsPage from 'src/components/DetailsPage'
import PermissionsTable from './components/PermissionsTable'
import UsersTable from './components/UsersTable'
import ViewPermissionsModal from './components/ViewPermissionsModal'
import ActionModal from 'src/components/ActionModal'
import { Menu } from 'antd'
import { moreActions } from './data'
import MoreActionsModal from './components/MoreActionsModal'
import {
    activateProviderGroupApi,
    deactivateProviderGroupApi,
    getProviderGroupUsersApi,
    getSingleProviderGroupApi,
    removeGroupUserApi,
    updateProviderGroupApi,
    getPermissionsByParentResourceApi,
    getPermissionsUnderRoleApi,
    updateAdminGroupStatusApi,
} from 'src/api/admin/settings/providergroup'
import { useQuery } from 'react-query'
import { format, parseISO } from 'date-fns'
import { errorHandler } from 'src/utils/errorHandler'
import Toast from 'src/components/Toast'
import {
    activateProvidersGroupApi,
    deactivateProvidersGroupApi,
    fetchPermissionsUnderProviderRoleApi,
    fetchPermissionsUnderRoleApi,
    fetchProviderGroupUsersApi,
    fetchSingleProviderGroupApi,
    removeProviderGroupUserApi,
    updateProvidersGroupApi,
} from 'src/api/providers/users/groups'

const Groups = ({ type }) => {
    const [users, setUsers] = useState([])
    const [action, setAction] = useState('')
    const [active, setActive] = useState(false)
    const [groupData, setGroupData] = useState([])
    const [parentId, setParentId] = useState('')
    const [permissionsGroupedByParent, setPermissionsGroupedByParent] =
        useState([])
    const [permissionsListGroupedByParent, setPermissionsListGroupedByParent] =
        useState([])
    const [userToView, setUserToView] = useState('')
    const [userToRemove, setUserToRemove] = useState('')
    const [inputs, setInputs] = useState({ workspace: 'PROVIDER' })

    //modal states
    const [showViewPermissionsModal, setShowViewPermissionsModal] =
        useState(false)
    const [showMoreActionsModal, setShowMoreActionsModal] = useState(false)
    const [warningModalOpen, setWarningModalOpen] = useState(false)
    const [successModalOpen, setSuccessModalOpen] = useState(false)

    //error states
    const [pageError, setPageError] = useState(false)
    const [pageErrorMessage, setPageErrorMessage] = useState('')
    const [modalError, setModalError] = useState(false)
    const [modalErrorMessage, setModalErrorMessage] = useState('')
    const [moreActionsError, setMoreActionsError] = useState(false)
    const [moreActionsErrorMessage, setMoreActionsErrorMessage] = useState('')

    const history = useHistory()
    const params = useParams()
    const isProvider = type === 'provider-user'

    const { refetch: groupRefetch, isFetching: groupIsFetching } = useQuery(
        'single-admin-provider-group',
        () =>
            isProvider
                ? fetchSingleProviderGroupApi(params?.id)
                : getSingleProviderGroupApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setGroupData(data?.data?.data)
                setActive(data?.data?.data?.role?.is_active)
            },
            onError: error => {
                setPageError(true)
                setPageErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const { refetch: groupUsersRefetch, isFetching: groupUsersFetching } =
        useQuery(
            'admin-provider-group-users',
            () =>
                isProvider
                    ? fetchProviderGroupUsersApi(groupData?.role?.id)
                    : getProviderGroupUsersApi(params?.id),
            {
                enabled: false,
                retry: false,
                onSuccess: data => {
                    setUsers(data?.data?.data)
                },
                onError: error => {
                    setPageError(true)
                    setPageErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    const {
        refetch: permissionsByParentRefetch,
        isFetching: permissionsByParentFetching,
    } = useQuery(
        'permissions-grouped-by-parent',
        () =>
            isProvider
                ? fetchPermissionsUnderProviderRoleApi(groupData?.role?.id)
                : getPermissionsByParentResourceApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                setPermissionsGroupedByParent(data?.data?.data)
            },
            onError: error => {
                setPageError(true)
                setPageErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const {
        refetch: permissionsListByParentRefetch,
        isFetching: permissionsListByParentFetching,
    } = useQuery(
        'list-of-permissions-grouped-by-parent',
        () =>
            isProvider
                ? fetchPermissionsUnderRoleApi(params?.id, parentId, false)
                : getPermissionsUnderRoleApi(params?.id, parentId, false),
        {
            enabled: false,
            retry: false,
            onSuccess: data => {
                const fetched = data?.data?.data
                let permissions = []
                for (let i = 0; i < fetched.length; i++) {
                    permissions.push({
                        id: fetched[i].permission?.id,
                        tree: [
                            fetched[i]?.resource?.parent_name,
                            fetched[i]?.resource?.name,
                            fetched[i]?.permission?.name,
                        ],
                    })
                }
                setPermissionsListGroupedByParent(permissions)
                setParentId('')
            },
            onError: error => {
                setPageError(true)
                setPageErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    const { refetch: updateGroupRefetch, isLoading: updateGroupLoading } =
        useQuery(
            'update-provider-group',
            () =>
                isProvider
                    ? updateProvidersGroupApi(params?.id, inputs)
                    : updateProviderGroupApi(params?.id, inputs),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    setShowMoreActionsModal(false)
                    setSuccessModalOpen(true)
                    setInputs({ workspace: 'PROVIDER' })
                    groupRefetch()
                },
                onError: error => {
                    setMoreActionsError(true)
                    setMoreActionsErrorMessage(
                        errorHandler(error?.response?.data),
                    )
                },
            },
        )

    //deactivate a provider group/role
    const {
        refetch: deactivateGroupRefetch,
        isLoading: deactivateGroupLoading,
    } = useQuery(
        'deactivate-provider-group',
        () =>
            isProvider
                ? deactivateProvidersGroupApi(params?.id)
                : deactivateProviderGroupApi(params?.id),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setWarningModalOpen(false)
                setSuccessModalOpen(true)
                groupRefetch()
            },
            onError: error => {
                setModalError(true)
                setModalErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //activate a provider group/role
    const { refetch: activateGroupRefetch, isLoading: activateGroupLoading } =
        useQuery(
            'activate-provider-group',
            () =>
                isProvider
                    ? activateProvidersGroupApi(params?.id)
                    : activateProviderGroupApi(params?.id),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    setWarningModalOpen(false)
                    setSuccessModalOpen(true)
                    groupRefetch()
                },
                onError: error => {
                    setModalError(true)
                    setModalErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    // deactivate and activate an admin group
    const {
        refetch: updateAdminGroupStatusRefetch,
        isLoading: updateAdminGroupStatusLoading,
    } = useQuery(
        'update-admin-group-status',
        () =>
            updateAdminGroupStatusApi(
                params?.id,
                action === 'deactivate-group' ? 'DEACTIVATE' : 'ACTIVATE',
            ),
        {
            enabled: false,
            retry: false,
            onSuccess: () => {
                setWarningModalOpen(false)
                setSuccessModalOpen(true)
                groupRefetch()
            },
            onError: error => {
                setModalError(true)
                setModalErrorMessage(errorHandler(error?.response?.data))
            },
        },
    )

    //remove a group user
    const { refetch: removeUserRefetch, isLoading: removeUserLoading } =
        useQuery(
            'remove-group-user',
            () =>
                isProvider
                    ? removeProviderGroupUserApi(userToRemove, params?.id)
                    : removeGroupUserApi(userToRemove, params?.id),
            {
                enabled: false,
                retry: false,
                onSuccess: () => {
                    setWarningModalOpen(false)
                    setSuccessModalOpen(true)
                    groupRefetch()
                    groupUsersRefetch()
                },
                onError: error => {
                    setModalError(true)
                    setModalErrorMessage(errorHandler(error?.response?.data))
                },
            },
        )

    useEffect(() => {
        groupRefetch()
        !isProvider && groupUsersRefetch()
        !isProvider && permissionsByParentRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupRefetch, groupUsersRefetch, permissionsByParentRefetch])

    useEffect(() => {
        isProvider && groupData?.role?.id?.length > 0 && groupUsersRefetch()
        isProvider &&
            groupData?.role?.id?.length > 0 &&
            permissionsByParentRefetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData])

    const handleMoreActions = action => {
        setAction(action)
        if (action === 'deactivate-group') {
            setWarningModalOpen(true)
        }

        if (action === 'rename-group') {
            setShowMoreActionsModal(true)
        }
        if (action === 'update-permissions') {
            handleUpdatePermissions()
        }
    }

    const menu = (
        <Menu style={{ marginTop: '0' }}>
            {moreActions.map((each, i) => (
                <Menu.Item
                    key={i}
                    style={{
                        display:
                            (active && i === 2) || (!active && i === 1)
                                ? 'none'
                                : '',
                    }}
                >
                    <span
                        className="AdminProvidersActiveDropdownLinks"
                        onClick={() => handleMoreActions(each?.action)}
                    >
                        {each.label}
                    </span>
                </Menu.Item>
            ))}
        </Menu>
    )

    const openViewPermissionsModal = id => {
        setParentId(id)
        setAction('change-permissions')
        setShowViewPermissionsModal(true)
    }

    useEffect(() => {
        if (parentId !== '') {
            permissionsListByParentRefetch()
        }
    }, [parentId, permissionsListByParentRefetch])

    const closeViewPermissionsModal = () => {
        setShowViewPermissionsModal(false)
    }

    const handleSavePermissionsChanges = () => {
        setShowViewPermissionsModal(false)
        setSuccessModalOpen(true)
        groupRefetch()
        permissionsByParentRefetch()
    }

    //more actions modal
    const closeMoreActionsModal = () => {
        setShowMoreActionsModal(false)
    }

    const handleMoreActionsSubmit = () => {
        if (action === 'rename-group') {
            updateGroupRefetch()
        }
    }

    //go to user page
    const handleViewUser = id => {
        setUserToView(id)
        if (type === 'admin') {
            history.push(`/admin/user/${id}`)
        }
        if (type === 'provider-user') {
            history.push(`/app/user/${id}`)
        }
        if (type === 'provider') {
            setAction('view-user')
            setWarningModalOpen(true)
        }
    }

    //func to remove user
    const handleRemoveUser = id => {
        setUserToRemove(id)
        setAction('remove-user')
        setWarningModalOpen(true)
    }

    const handleWarningModalAction = () => {
        if (action === 'remove-user') {
            removeUserRefetch()
        }
        if (
            action === 'deactivate-group' &&
            (type === 'provider' || type === 'provider-user')
        ) {
            deactivateGroupRefetch()
        }

        if (
            action === 'activate-group' &&
            (type === 'provider' || type === 'provider-user')
        ) {
            activateGroupRefetch()
        }

        if (
            (action === 'deactivate-group' || action === 'activate-group') &&
            type === 'admin'
        ) {
            updateAdminGroupStatusRefetch()
        }

        if (action === 'view-user') {
            history.push(`/admin/providers/user/${userToView}`)
        }
    }

    //activate group
    const handleActivateGroup = () => {
        setAction('activate-group')
        setWarningModalOpen(true)
    }

    //switching to update permissions page
    const handleUpdatePermissions = () => {
        if (type === 'admin') {
            history.push(`/admin/group/update-group-permissions/${params?.id}`)
        }
        if (type === 'provider-user') {
            history.push(`/app/group/update-group-permissions/${params?.id}`)
        }
        if (type === 'provider') {
            history.push(
                `/admin/settings/update-group-permissions/${params?.id}`,
            )
        }
    }

    const warningTitleMapping = {
        'remove-user': 'Remove User?',
        'deactivate-group': 'Deactivate Group?',
        'view-user': 'Exit Settings?',
        'activate-group': 'Activate Group?',
    }

    const warningBtnMapping = {
        'remove-user': 'Remove',
        'deactivate-group': 'Deactivate',
        'view-user': 'Continue',
        'activate-group': 'Activate',
    }

    const successTitleMapping = {
        'change-permissions': 'Permissions Changed Successfully',
        'remove-user': 'User Removed Successfully',
        'deactivate-group': 'Group Deactivated Successfully',
        'rename-group': 'Group Renamed Successfully',
        'activate-group': 'Group Activated Successfully',
    }

    return (
        <>
            {/*warning modal*/}
            <ActionModal
                actionType="warning"
                actionModalOpen={warningModalOpen}
                headerText={warningTitleMapping[action]}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText={warningBtnMapping[action]}
                closable
                onCancel={() => setWarningModalOpen(false)}
                closeModal={() => setWarningModalOpen(false)}
                actionHandler={() => handleWarningModalAction()}
                noBackLink
                loading={
                    deactivateGroupLoading ||
                    activateGroupLoading ||
                    removeUserLoading ||
                    updateAdminGroupStatusLoading
                }
                toastError={modalError}
                errorMessage={modalErrorMessage}
            />

            {/*success modal*/}
            <ActionModal
                actionType="success"
                actionModalOpen={successModalOpen}
                headerText={successTitleMapping[action]}
                subTitle="We’ve solved the problems that make it difficult for energy providers to transition millions"
                actionText="Okay, Got it"
                closable
                onCancel={() => setSuccessModalOpen(false)}
                actionHandler={() => setSuccessModalOpen(false)}
                noBackLink
                noCancelBtn
            />
            <ViewPermissionsModal
                showModal={showViewPermissionsModal}
                onCancel={closeViewPermissionsModal}
                handleSave={() => handleSavePermissionsChanges()}
                permissions={permissionsListGroupedByParent}
                isFetching={permissionsListByParentFetching}
                id={params?.id}
                userType={type}
            />

            <MoreActionsModal
                showModal={showMoreActionsModal}
                onCancel={closeMoreActionsModal}
                action={action}
                groupName={groupData?.role?.name}
                handleSubmit={handleMoreActionsSubmit}
                inputs={inputs}
                setInputs={setInputs}
                isLoading={updateGroupLoading}
                error={moreActionsError}
                errorMessage={moreActionsErrorMessage}
            />
            {/*page*/}
            {pageError && (
                <Toast messageType="error" message={pageErrorMessage} />
            )}
            <DetailsPage
                noTopInfo
                backNavTitle={
                    type === 'admin' || type === 'provider-user'
                        ? 'Back to Groups'
                        : type === 'provider'
                        ? 'Back to Settings'
                        : ''
                }
                backNavUrl={
                    type === 'admin'
                        ? '/admin/users/listing?tab=2'
                        : type === 'provider-user'
                        ? '/app/users/listing?tab=2'
                        : type === 'provider'
                        ? '/admin/settings?tab=2'
                        : ''
                }
                name={groupData?.role?.name ?? 'N/A'}
                status={active ? 'ACTIVE' : 'DEACTIVATED'}
                tabPaneOneTab="Permissions"
                tabPaneOneContent={
                    <PermissionsTable
                        openModal={openViewPermissionsModal}
                        data={permissionsGroupedByParent}
                    />
                }
                tabPaneTwo
                tabPaneTwoTab="Users"
                tabPaneTwoContent={
                    <UsersTable
                        data={users}
                        viewUser={handleViewUser}
                        removeUser={handleRemoveUser}
                        type={type}
                    />
                }
                rightSectionHeading="Group Details"
                rowCount={2}
                gridItemOneHeading="CREATION DATE"
                gridItemOneContent={
                    groupData?.role?.created_at !== undefined &&
                    format(
                        parseISO(groupData?.role?.created_at),
                        'dd MMM, yyyy, hh:mm ',
                    ).toUpperCase()
                }
                gridItemTwoHeading="CREATED BY"
                gridItemTwoContent={groupData?.role?.created_by ?? 'N/A'}
                gridItemThreeHeading="NO. PERMISSIONS"
                gridItemThreeContent={groupData?.permissions_count ?? 'N/A'}
                gridItemFourHeading="NO. USERS"
                gridItemFourContent={groupData?.users_count ?? 'N/A'}
                actionButton
                buttonOneText={active ? 'Update Permissions' : 'Activate'}
                buttonOneHandleClick={() =>
                    active ? handleUpdatePermissions() : handleActivateGroup()
                }
                secondButton
                secondButtonType="moreActions"
                buttonTwoText="More Actions"
                buttonOneWidth="147px"
                dropdownMenu={menu}
                isLoading={
                    groupIsFetching ||
                    groupUsersFetching ||
                    permissionsByParentFetching
                }
            />
        </>
    )
}

Groups.propTypes = {
    type: PropTypes.string,
}
export default Groups
