import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { useAppContext } from 'src/appContext/AppContext'
import LinkIcon from 'src/assets/images/link.svg'
import { ConsumerDateField, SelectField } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import { pensionManagers } from 'src/utils/pensionManagers'
import { usePersonalInfoContext } from '../../../../context'
import { appTracking } from 'src/utils/appTracker'

const RetiredPersonnelDetails = ({
    showCalendar,
    setShowCalendar,
    isAdminUser,
    updateErrors,
    removeFromUpdateErrs,
    isAssistedKyc,
}) => {
    const { isCustomerApproved } = useAppContext()
    const { employmentInfoFormik } = usePersonalInfoContext()
    const {
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        setFieldError,
        handleChange,
        handleBlur,
    } = employmentInfoFormik

    const pageTitle =
        'KYC - Residential Basic Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_EMPLOYMENT_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    return (
        <>
            <IconInputField
                icon={LinkIcon}
                name="linkedinPage"
                title="Linkedin Page"
                width={'100%'}
                value={values?.linkedinPage}
                handleChange={handleChange}
                onBlur={e => {
                    handleBlur(e)
                    handleTracking(e)
                }}
                errorMessage={touched.linkedinPage && errors.linkedinPage}
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />
            <p className="OptionalTypoGraphy">Optional</p>
            <SelectField
                floatLabelOnSelectedValue
                floatingLabel="Pension Manager"
                name="pensionManager"
                initialOption="Pension Manager"
                selectWidth="100%"
                marginBottom="24px"
                values={pensionManagers.map(option => ({
                    value: option,
                }))}
                value={values.pensionManager}
                errorMessage={
                    updateErrors.pensionManager ||
                    (touched.pensionManager && errors?.pensionManager)
                }
                currentSelected={values?.pensionManager}
                handleChange={(name, value) => {
                    setFieldValue('pensionManager', value)
                    removeFromUpdateErrs('pensionManager')
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        `FILLED_${name?.toUpperCase()}`,
                        'SMART_SHOPPING_EXPERIENCE_KYC',
                        'KYC_EMPLOYMENT_INFO',
                        ['GA', 'MP'],
                        'event',
                        { value },
                    )
                }}
                onBlur={() => {
                    setFieldTouched('pensionManager', true)
                    if (!values.pensionManager)
                        setFieldError('pensionManager', errors.pensionManager)
                }}
                withCheckBox
                dropdownPositionRelative
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />
            <ConsumerDateField
                title="When did you retire?"
                width={'100%'}
                name="retirementDate"
                caretStyle="caretStyle"
                dateValue={
                    values?.retirementDate?.length === 0
                        ? null
                        : new Date(values?.retirementDate)
                }
                value={values?.retirementDate}
                errorMessage={
                    updateErrors.retirementDate ||
                    (touched.retirementDate && errors?.retirementDate)
                }
                handleDateChange={(name, date) => {
                    setFieldValue(
                        'retirementDate',
                        format(new Date(date), 'MMM dd, yyyy'),
                    )
                    removeFromUpdateErrs('retirementDate')
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        `FILLED_${name?.toUpperCase()}`,
                        'SMART_SHOPPING_EXPERIENCE_KYC',
                        'KYC_EMPLOYMENT_INFO',
                        ['GA', 'MP'],
                        'event',
                        { date },
                    )
                }}
                openCalendar={showCalendar}
                closeCalendarHandler={() => {
                    setShowCalendar(false)
                    if (!values.retirementDate) {
                        setFieldError(
                            'retirementDate',
                            'Please provide your retirement date',
                        )
                    }
                }}
                openCalendarHandler={() => {
                    setShowCalendar(true)
                    setFieldTouched('retirementDate', true)
                }}
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />
        </>
    )
}

RetiredPersonnelDetails.propTypes = {
    showCalendar: PropTypes.bool.isRequired,
    setShowCalendar: PropTypes.func.isRequired,
    isAdminUser: PropTypes.bool,
    updateErrors: PropTypes.object,
    removeFromUpdateErrs: PropTypes.func,
    isAssistedKyc: PropTypes.bool,
}

export default RetiredPersonnelDetails
