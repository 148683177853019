export const unitsOfMeasurementOptions = [{ value: 'Watts' }, { value: 'kVa' }]

export const filterDrawerValues = tab => {
    switch (tab) {
        case '1':
            return {
                filterDrawerTitle: 'Filter Appliances',
                minInputTitle: 'Min. Rating',
                maxInputTitle: 'Max. Rating',
            }
        case '2':
            return {
                filterDrawerTitle: 'Filter Provider Groups',
                minInputTitle: 'Min. users',
                maxInputTitle: 'Max. users',
                // TODO: add other values when working on provider groups
            }

        case '4':
            return {
                filterDrawerTitle: 'Filter Component Types',
            }

        default:
            return {
                filterDrawerTitle: 'Filter',
            }
    }
}
