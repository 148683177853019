import './appliancespreview.scss'
import { ReactComponent as WarningIcon } from 'src/assets/images/shield-warning.svg'
import { ReactComponent as HelpIcon } from 'src/assets/images/help-circle.svg'
import PropTypes from 'prop-types'
import CustomToolTip from '../CustomToolTip'
import Checkbox from '../Checkbox'
import { applianceIcon } from 'src/pages/Consumer/ProfileListings/data'

const AppliancesPreview = ({
    systemDetails,
    appliances,
    handleChecked,
    checked,
    isQuickView,
}) => {
    return (
        <>
            <div className="AppliancesPreviewContainer">
                <div className="ImportantNotice">
                    <p className="ImportantNoticeText">
                        <span className="WarningIcon">
                            <WarningIcon />
                        </span>
                        Important information to note
                    </p>
                    <p>
                        To maintain your{' '}
                        {systemDetails?.energy_capacity?.value +
                            systemDetails?.energy_capacity
                                ?.unit_of_measurement}{' '}
                        system&apos;s durability,please follow the guidelines of
                        the appliances and ensure you turn off items to conserve
                        energy otherwise the hours provided will be severely
                        reduced.This helps the batteries and inverter last
                        longer, ensuring reliable power availability when
                        necessary. It&apos;s important to follow this guideline
                        to make sure your system stays reliable.
                    </p>
                </div>

                <div className="AppliancesPreview">
                    <h6>
                        Appliance Breakdown for this system
                        <CustomToolTip
                            text="This is a breakdown of what each appliance can power, including the hours it can be used during the day and night."
                            toolTipFontSize="12px"
                            toolTipPadding="6px 11px"
                            toolTipWidth="200px"
                        >
                            <HelpIcon className="HelpIcon" />
                        </CustomToolTip>
                    </h6>
                    <ul className="AllAppliances">
                        {appliances.map(appliance => (
                            <li key={appliance.id} className="ApplianceItem">
                                <div className="ApplianceTitle">
                                    <img
                                        src={applianceIcon(appliance.name)}
                                        alt="icon"
                                        role="presentation"
                                    />
                                    <p className="ApplianceName">
                                        {appliance.quantity} {appliance.name}(s)
                                    </p>
                                </div>
                                <div className="ApplianceInfo">
                                    <div>
                                        <p className="InfoTitle">
                                            Daytime Usage
                                        </p>
                                        <p>{appliance.backup_hours} hours</p>
                                    </div>
                                    <div>
                                        <p className="InfoTitle">
                                            Nighttime Usage
                                        </p>
                                        <p>
                                            {appliance.backup_hours_night} hours
                                        </p>
                                    </div>
                                    <div>
                                        <p className="InfoTitle">
                                            Power Rating
                                        </p>
                                        <p>
                                            {appliance.power_rating ??
                                                appliance.powerRating}{' '}
                                            {appliance.unitOfMeasurement ??
                                                appliance.unit_of_measurement}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="PreviewFooter">
                <div className="Agreement">
                    <Checkbox
                        handleChecked={handleChecked}
                        signupchecked={checked}
                        isChecked={checked}
                    >
                        {isQuickView ? (
                            <p>
                                I have gone through the appliance breakdown and
                                also understand the peak power of the system
                            </p>
                        ) : (
                            <p>
                                I have reviewed the appliance breakdown and I am
                                convinced that it meets my requirements.
                            </p>
                        )}
                    </Checkbox>
                </div>
            </div>
        </>
    )
}

AppliancesPreview.propTypes = {
    systemDetails: PropTypes.any,
    appliances: PropTypes.arrayOf,
    handleChecked: PropTypes.func,
    checked: PropTypes.bool,
    isQuickView: PropTypes.bool,
}

export default AppliancesPreview
