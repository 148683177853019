import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/PersonalInformation/ResidenceForm/residenceform.scss'
import { InputFields } from 'src/components/InputFields'
import Label from '../../../../PersonalInformation/_components/Label'
import { sharedFormComponentPropTypes } from '../utils'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'

const Bvn = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
    openBVNModal,
    bvnVerificationInProgress,
}) => {
    const { isMobile } = useMediaQueries()

    return (
        <>
            <Label
                title={
                    isAdminUser
                        ? `Customer's Bank Verification Number`
                        : 'Add your Bank Verification Number'
                }
                subTitle={
                    isAdminUser
                        ? ''
                        : 'Please provide your bank verification number. Your BVN does not give us access to your bank account. We use it to verify your credit history'
                }
                showHelpIcon={true}
                tooltipText={
                    'With your BVN, we can validate your name, date of birth and also credit history through credit bureau.'
                }
            />
            <div className="VerifyInputWrapper OSIFormPhoneWrapper">
                <InputFields
                    title="Bank Verification Number"
                    name={'bvn'}
                    value={inputs?.bvn}
                    errorMessage={errors?.bvn || errors?.bvnVerified}
                    handleChange={handleChange}
                    onBlur={handleOnBlur}
                    disabled={
                        isAdminUser ||
                        isCustomerApproved ||
                        bvnVerificationInProgress ||
                        inputs.bvnVerified
                    }
                    type={inputs.bvnVerified ? 'text' : 'number'}
                    inprogress={bvnVerificationInProgress}
                    verified={inputs.bvnVerified}
                />
                {!isAdminUser &&
                    !inputs?.bvnVerified &&
                    !bvnVerificationInProgress && (
                        <div className="VerifyCTA">
                            <button
                                style={{ width: 90 }}
                                disabled={
                                    inputs.bvn === '' ||
                                    inputs?.bvn?.length < 11
                                }
                                onClick={openBVNModal}
                            >
                                {isMobile ? 'Verify' : 'Verify BVN'}
                            </button>
                        </div>
                    )}
            </div>
        </>
    )
}
Bvn.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default Bvn
