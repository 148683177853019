import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputFields, SelectField } from 'src/components/InputFields'
import Label from '../../../_components/Label'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import Toast from 'src/components/Toast'
import employmentStatus from 'src/utils/employmentStatus'
import UploadModal from 'src/components/UploadModal'
import { employmentContractFileTypes } from 'src/utils/supportedFileTypes'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { isMobile } from 'src/utils/mediaQueries'
import SelfEmployedPersonnelDetails from './components/SelfEmployedAdditionalForm'
import ModalContent from './components/ModalContent'
import RetiredPersonnelDetails from './components/RetiredPersonnelAdditionForm'
import EmployedPersonnelDetails from './components/EmployedPersonnelAdditionalForm'
import { useSSEVerifyConsumerBusiness } from 'src/api/shoppingExperience/kyc/queries'
import { errorHandler } from 'src/utils/errorHandler'
import { PersonalInfoContext } from '../../../context'
import CaretCircle from '../../../../../../../../assets/images/circle-left-white.svg'
import { decodeUserInfo } from 'src/utils/auth'
import FormSkeleton from '../../../../_components/FormSkeleton'
import { useAppContext } from 'src/appContext/AppContext'
import { appTracking } from 'src/utils/appTracker'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'
import VerificationFailedContent from '../../../../Verification/components/verificationHelp'

const EmploymentInfo = ({
    setCurrentTab,
    updateErrors,
    removeFromUpdateErrs,
    setShowVideoModal,
}) => {
    const value = useContext(PersonalInfoContext)
    const { isCustomerApproved } = useAppContext()
    const {
        refetchInfo,
        employmentInfoFormik,
        sseBasicInfoIsFetching,
        residentialInfoInputs,
    } = value
    const {
        values,
        touched,
        handleChange,
        setFieldError,
        errors,
        setFieldTouched,
        setFieldValue,
        setValues,
        handleBlur,
    } = employmentInfoFormik
    const defaultEmploymentType = values?.employmentType?.length === 0
    const employed = ['Part Time', 'Full Time', 'Contract'].includes(
        values?.employmentType,
    )
    const pageTitle =
        'KYC - Residential Basic Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const selfEmployed = values?.employmentType === 'Self Employed'
    const retired = values?.employmentType === 'Retired'
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [
        successEmploymentContractUpload,
        setSuccessEmploymentContractUpload,
    ] = useState([])
    const [employmentContract, setEmploymentContract] = useState([])
    const [showCalendar, setShowCalendar] = useState(false)
    const [verificationModal, setVerificationModal] = useState(false)
    const [cacVerificationFailed, setCACVerificationFailed] = useState(false)
    const [verificationInProgress, setVerificationInProgress] = useState(false)

    const [toastMessage, setToastMessage] = useState('')
    const [toastModalMessage, setToastModalMessage] = useState('')
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const shouldDisplayVideo =
        !isAdminUser && process.env.REACT_APP_FLAG_SHOW_HOW_TO_VIDEO === 'true'
    const isAssistedKyc =
        process.env.REACT_APP_FLAG_ADMIN_ASSISTED_KYC === 'true'

    const closeModal = () => {
        setVerificationModal(false)
        setCACVerificationFailed(false)
    }

    const handleCloseUploadModal = () => {
        setShowUploadModal(false)
        setSuccessEmploymentContractUpload([])
    }

    useEffect(() => {
        if (employmentContract[0]?.name?.length > 0) {
            setValues(prev => ({
                ...prev,
                employmentSuccessUpload: employmentContract[0],
            }))
        }
    }, [employmentContract, setValues])

    // consumer business verification
    const { verifyConsumerBusiness, consumerBusinessVerificationLoading } =
        useSSEVerifyConsumerBusiness({
            onSuccess: data => {
                refetchInfo()
                if (data?.data?.result) {
                    closeModal()
                    setToastMessage(
                        'Your CAC number has been verified successfully',
                    )
                } else if (
                    data?.data?.result === false &&
                    data?.data?.cac_server_error === true
                ) {
                    setCACVerificationFailed(true)
                } else {
                    setToastModalMessage(data.message)
                }
            },
            onError: err => {
                setToastModalMessage(errorHandler(err))
            },
        })

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_EMPLOYMENT_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    const cacHelpPayload = {
        email: residentialInfoInputs?.email || 'support@sunfi.co',
        name: residentialInfoInputs?.businessName,
        message: 'CAC Verification failed and consumer is requesting for help',
        phone_number: residentialInfoInputs?.phoneNumber || '+2347080639920',
    }

    return (
        <>
            {/* Modal Start */}
            <UploadModal
                showModal={showUploadModal}
                onCancel={handleCloseUploadModal}
                title={'Employment Contract'}
                primaryBtnText={'Confirm'}
                successUpload={successEmploymentContractUpload}
                setSuccessUpload={setSuccessEmploymentContractUpload}
                acceptableFileTypes={employmentContractFileTypes}
                setFileToState={setEmploymentContract}
            />
            <SecondaryModal
                showModal={verificationModal}
                onCancel={() => closeModal()}
                modalWidth={'475px'}
                title={
                    cacVerificationFailed
                        ? 'Unable to verify'
                        : 'Verify CAC number'
                }
                v2
                content={
                    <>
                        {cacVerificationFailed ? (
                            <VerificationFailedContent
                                type="cac"
                                payload={cacHelpPayload}
                                onCancel={closeModal}
                                setVerificationInProgress={
                                    setVerificationInProgress
                                }
                                toastMessage={toastModalMessage}
                                setToastMessage={setToastModalMessage}
                            />
                        ) : (
                            <ModalContent
                                toastMessage={toastModalMessage}
                                setToastMessage={setToastModalMessage}
                            />
                        )}
                    </>
                }
                showFooter
                footerLeftContent={cacVerificationFailed ? 'chat' : 'none'}
                primaryBtnText={cacVerificationFailed ? 'Try again' : 'Confirm'}
                handlePrimaryBtnClick={() =>
                    verifyConsumerBusiness({
                        business_name: values?.businessName,
                        cac_number: values?.cacNumber,
                    })
                }
                primaryBtnLoading={consumerBusinessVerificationLoading}
                primaryBtnWidth={isMobile ? '127.15px' : '160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
            />
            {/* Modal End */}
            <Toast
                message={toastMessage}
                messageType="success-secondary"
                setToast={setToastMessage}
                closable={true}
                position="fixed"
                left="12%"
                top="13.5%"
                zIndex="5"
            />
            {sseBasicInfoIsFetching ? (
                <FormSkeleton />
            ) : (
                <>
                    {shouldDisplayVideo && (
                        <div
                            className="BusinessFormPageVideoMobile"
                            onClick={() => setShowVideoModal(true)}
                        >
                            <PlayIcon />
                            <p>Watch how to fill your basic info</p>
                        </div>
                    )}
                    <Label
                        title="Employment type"
                        subTitle={
                            isAdminUser
                                ? ''
                                : 'What type of employment are you currently on?'
                        }
                    />
                    <SelectField
                        floatLabelOnSelectedValue
                        floatingLabel="Employment Type"
                        name="employmentType"
                        initialOption="Employment Type"
                        selectWidth="100%"
                        marginBottom="24px"
                        values={employmentStatus.map(option => ({
                            value: option,
                        }))}
                        value={values.employmentType}
                        handleChange={(_, value) => {
                            setFieldValue('employmentType', value)
                            removeFromUpdateErrs('employmentType')
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                `FILLED_${_?.toUpperCase()}`,
                                'SMART_SHOPPING_EXPERIENCE_KYC',
                                'KYC_EMPLOYMENT_INFO',
                                ['GA', 'MP'],
                                'event',
                                { value },
                            )
                        }}
                        onBlur={() => {
                            setFieldTouched('employmentType', true)
                            if (!values.employmentType)
                                setFieldError(
                                    'employmentType',
                                    errors.employmentType,
                                )
                        }}
                        currentSelected={values.employmentType}
                        errorMessage={
                            updateErrors.employmentType ||
                            (touched.employmentType && errors.employmentType)
                        }
                        withCheckBox
                        dropdownPositionRelative
                        disabled={
                            (!isAssistedKyc && isAdminUser) ||
                            isCustomerApproved
                        }
                    />
                    {(employed || defaultEmploymentType) && (
                        <>
                            <Label
                                title={
                                    isAdminUser
                                        ? "Customer's work information"
                                        : 'Where do you work?'
                                }
                                subTitle={
                                    isAdminUser
                                        ? ''
                                        : 'Provide us with your employer’s details and what you do'
                                }
                            />
                            <InputFields
                                title="Company Name"
                                name="companyName"
                                value={values.companyName}
                                errorMessage={
                                    updateErrors.companyName ||
                                    (touched.companyName && errors.companyName)
                                }
                                handleChange={e => {
                                    handleChange(e)
                                    removeFromUpdateErrs('companyName')
                                }}
                                onBlur={e => {
                                    handleBlur(e)
                                    handleTracking(e)
                                }}
                                disabled={
                                    (!isAssistedKyc && isAdminUser) ||
                                    isCustomerApproved
                                }
                            />
                            <InputFields
                                title="Job Title"
                                name="jobTitle"
                                value={values.jobTitle}
                                errorMessage={
                                    updateErrors.jobTitle ||
                                    (touched.jobTitle && errors?.jobTitle)
                                }
                                handleChange={e => {
                                    handleChange(e)
                                    removeFromUpdateErrs('jobTitle')
                                }}
                                onBlur={e => {
                                    handleBlur(e)
                                    handleTracking(e)
                                }}
                                disabled={
                                    (!isAssistedKyc && isAdminUser) ||
                                    isCustomerApproved
                                }
                            />
                            <VerticalSpacer bottom="8px" />
                        </>
                    )}

                    <Label
                        title="Employment details"
                        subTitle={
                            isAdminUser
                                ? ''
                                : 'Provide us with more details about your work'
                        }
                        showHelpIcon={selfEmployed}
                        tooltipText={
                            'With your CAC number, we can validate your business name, and also verify your business.'
                        }
                    />
                    {employed || defaultEmploymentType ? (
                        <EmployedPersonnelDetails
                            showCalendar={showCalendar}
                            setShowCalendar={setShowCalendar}
                            employmentContract={employmentContract}
                            setEmploymentContract={setEmploymentContract}
                            setShowUploadModal={setShowUploadModal}
                            isAdminUser={isAdminUser}
                            updateErrors={updateErrors}
                            removeFromUpdateErrs={removeFromUpdateErrs}
                            isAssistedKyc={isAssistedKyc}
                        />
                    ) : retired ? (
                        <RetiredPersonnelDetails
                            showCalendar={showCalendar}
                            setShowCalendar={setShowCalendar}
                            isAdminUser={isAdminUser}
                            updateErrors={updateErrors}
                            removeFromUpdateErrs={removeFromUpdateErrs}
                            isAssistedKyc={isAssistedKyc}
                        />
                    ) : selfEmployed ? (
                        <SelfEmployedPersonnelDetails
                            setVerificationModal={setVerificationModal}
                            isAdminUser={isAdminUser}
                            updateErrors={updateErrors}
                            removeFromUpdateErrs={removeFromUpdateErrs}
                            verificationInProgress={verificationInProgress}
                            isAssistedKyc={isAssistedKyc}
                        />
                    ) : (
                        ''
                    )}

                    <div className="formStep__emp">
                        <button
                            onClick={() => {
                                setCurrentTab('1')
                            }}
                        >
                            <span>
                                <img
                                    src={CaretCircle}
                                    alt="right icon"
                                    width={14}
                                    height={14}
                                />
                            </span>{' '}
                            Go back to personal info
                        </button>
                    </div>
                </>
            )}
        </>
    )
}

EmploymentInfo.propTypes = {
    setCurrentTab: PropTypes.func.isRequired,
    updateErrors: PropTypes.object,
    removeFromUpdateErrs: PropTypes.func,
    setShowVideoModal: PropTypes.func,
}

export default EmploymentInfo
