import PropTypes from 'prop-types'

export const footerPropTypes = {
    footerLeftContent: PropTypes.oneOf(['chat', 'button', null]),
    chatCTAText: PropTypes.string,
    secondaryBtnWidth: PropTypes.number || PropTypes.string,
    secondaryBtnHeight: PropTypes.number || PropTypes.string,
    handleSecondaryBtnClick: PropTypes.func,
    secondaryBtnDisabled: PropTypes.bool,
    secondaryBtnLoading: PropTypes.bool,
    secondaryBtnText: PropTypes.string,
    primaryBtnWidth: PropTypes.number || PropTypes.string,
    primaryBtnHeight: PropTypes.number || PropTypes.string,
    handlePrimaryBtnClick: PropTypes.func,
    primaryBtnDisabled: PropTypes.bool,
    primaryBtnLoading: PropTypes.bool,
    primaryBtnText: PropTypes.string,
    footerClassName: PropTypes.string,
}

export const mobileModalPropTypes = {
    mobileHeight: PropTypes.string || PropTypes.number,
}

export const standardModalPropTypes = {
    standardWidth: PropTypes.number || PropTypes.string,
    standardHeight: PropTypes.number || PropTypes.string,
}
