import PropTypes from 'prop-types'
import { Menu } from 'antd'
import { dropdownMenuOptions } from '../utils'

const DropdownMenu = ({
    openMemberDetailsModal,
    storeActionAndOpenModal,
    status,
    member,
    pageAction,
    openFileUploadModal,
}) => {
    return (
        <Menu>
            {dropdownMenuOptions?.map((item, i) => {
                const shouldSkipItem =
                    !item.page.includes(pageAction) ||
                    (item.action === 'upload-individual-credit-report' &&
                        (member?.has_credit_report ||
                            member?.system_size?.value === undefined)) ||
                    ((item.action === 'resend-link' ||
                        item.action === 'remove-member') &&
                        (status !== 'Inactive' || member.due_payment)) ||
                    (item.action === 'make-payment' && !member.due_payment)

                if (shouldSkipItem) {
                    return null
                }

                return (
                    <Menu.Item
                        key={i}
                        onClick={() => {
                            switch (item?.action) {
                                case 'view-member-details':
                                    openMemberDetailsModal(member)
                                    break
                                case 'resend-link':
                                    storeActionAndOpenModal(
                                        'resend-system-selection-link',
                                        member,
                                    )
                                    break
                                case 'make-payment':
                                    storeActionAndOpenModal(
                                        'make-single-payment-confirmation-request',
                                        member,
                                    )
                                    break
                                case 'remove-member':
                                    storeActionAndOpenModal(
                                        'remove-member-confirmation-request',
                                        member,
                                    )
                                    break
                                case 'upload-individual-credit-report':
                                    openFileUploadModal(member)
                                    break
                                case 'remove-credit-report-member':
                                    storeActionAndOpenModal(
                                        'remove-credit-report-member-confirmation-request',
                                        member,
                                    )
                                    break
                                default:
                                    break
                            }
                        }}
                    >
                        <span
                            style={{
                                color:
                                    item?.action === 'remove-member'
                                        ? '#DA1E28'
                                        : '#233862',
                            }}
                        >
                            {item?.title}
                        </span>
                    </Menu.Item>
                )
            })}
        </Menu>
    )
}

DropdownMenu.propTypes = {
    openMemberDetailsModal: PropTypes.func,
    storeActionAndOpenModal: PropTypes.func,
    status: PropTypes.string,
    member: PropTypes.object,
    pageAction: PropTypes.string,
    openFileUploadModal: PropTypes.func,
}

export default DropdownMenu
