import PropTypes from 'prop-types'
import { useState } from 'react'
import { ConsumerDateField } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import LinkIcon from 'src/assets/images/link.svg'

import UploadField from 'src/components/InputFields/UploadField'
import { extractFileNameFromUrl } from 'src/utils/formatting'
import { usePersonalInfoContext } from '../../../../context'
import { format } from 'date-fns'
import RemoveFileModal from 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/_components/RemoveFileModal'
import { useAppContext } from 'src/appContext/AppContext'
import { appTracking } from 'src/utils/appTracker'
const EmployedPersonnelDetails = ({
    showCalendar,
    setShowCalendar,
    employmentContract,
    setEmploymentContract,
    setShowUploadModal,
    isAdminUser,
    updateErrors,
    removeFromUpdateErrs,
    isAssistedKyc,
}) => {
    const [showRemoveFileModal, setShowRemoveFileModal] = useState(false)
    const [fileToBeRemoved, setFileToBeRemoved] = useState({
        key: '',
        title: '',
        url: '',
        fileType: '',
    })
    const { isCustomerApproved } = useAppContext()
    const { employmentInfoFormik } = usePersonalInfoContext()
    const {
        values,
        errors: formikErrors,
        setValues,
        touched,
        setFieldTouched,
        setFieldValue,
        setFieldError,
        handleChange,
        handleBlur,
    } = employmentInfoFormik

    const pageTitle =
        'KYC - Residential Basic Information | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const handleRemoveFile = (key, title, url) => {
        setFileToBeRemoved({
            key: key,
            title: title,
            url: url,
            fileType:
                key === 'employment-contract' ? 'EMPLOYMENT_CONTRACT' : '',
        })
        setShowRemoveFileModal(true)
    }

    const closeRemoveFileModal = () => {
        setFileToBeRemoved({
            key: '',
            title: '',
            url: '',
            fileType: '',
        })
        setShowRemoveFileModal(false)
    }

    const closeRemoveFileModalAfterSuccess = () => {
        if (fileToBeRemoved.key === 'employment-contract') {
            setEmploymentContract([])
            if (values?.employmentContractUrl?.length > 0) {
                setValues(prev => ({
                    ...prev,
                    employmentContractUrl: '',
                }))
            }
        }

        setShowRemoveFileModal(false)
    }

    const handleTracking = e => {
        const { name, value } = e.target
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            `FILLED_${name?.toUpperCase()}`,
            'SMART_SHOPPING_EXPERIENCE_KYC',
            'KYC_EMPLOYMENT_INFO',
            ['GA', 'MP'],
            'event',
            { value },
        )
    }

    return (
        <>
            <RemoveFileModal
                showModal={showRemoveFileModal}
                fileToBeRemoved={fileToBeRemoved}
                closeRemoveFileModal={closeRemoveFileModal}
                closeRemoveFileModalAfterSuccess={
                    closeRemoveFileModalAfterSuccess
                }
            />
            <ConsumerDateField
                title="When did you start at the company"
                width={'100%'}
                name="jobStartDate"
                caretStyle="caretStyle"
                dateValue={
                    values?.jobStartDate?.length === 0
                        ? null
                        : new Date(values?.jobStartDate)
                }
                value={values?.jobStartDate}
                errorMessage={
                    updateErrors?.jobStartDate ||
                    (touched.jobStartDate && formikErrors?.jobStartDate)
                }
                handleDateChange={(name, date) => {
                    setFieldValue(
                        'jobStartDate',
                        format(new Date(date), 'MMM dd, yyyy'),
                    )
                    removeFromUpdateErrs('jobStartDate')
                    appTracking(
                        pageUrlName,
                        pageTracker,
                        pageTitle,
                        `FILLED_${name?.toUpperCase()}`,
                        'SMART_SHOPPING_EXPERIENCE_KYC',
                        'KYC_EMPLOYMENT_INFO',
                        ['GA', 'MP'],
                        'event',
                        { date },
                    )
                }}
                closeCalendarHandler={() => {
                    setShowCalendar(false)
                    if (!values.jobStartDate) {
                        setFieldError(
                            'jobStartDate',
                            'Please provide your job start date',
                        )
                    }
                }}
                openCalendar={showCalendar}
                openCalendarHandler={() => {
                    setShowCalendar(true)
                    setFieldTouched('jobStartDate', true)
                }}
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />

            <UploadField
                btnText="Employment contract"
                handleUploadClick={() => setShowUploadModal(true)}
                handleRemoveClick={() => {
                    if (values?.employmentContractUrl?.length > 0) {
                        handleRemoveFile(
                            'employment-contract',
                            'employment contract',
                            values?.employmentContractUrl,
                        )
                    } else {
                        setEmploymentContract([])
                        if (values?.employmentContractUrl?.length > 0) {
                            setValues(prev => ({
                                ...prev,
                                employmentContractUrl: '',
                            }))
                        }
                    }
                }}
                fileUploaded={
                    values?.employmentContractUrl?.length > 0 ||
                    employmentContract?.length > 0
                }
                fileName={
                    extractFileNameFromUrl(values?.employmentContractUrl) ||
                    employmentContract[0]?.name
                }
                margin={'0 0 20px 0'}
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />
            <p className="OptionalTypoGraphy">Optional</p>

            <IconInputField
                icon={LinkIcon}
                name="linkedinPage"
                title="Linkedin Page"
                width={'100%'}
                value={values?.linkedinPage}
                handleChange={handleChange}
                onBlur={e => {
                    handleBlur(e)
                    handleTracking(e)
                }}
                errorMessage={
                    touched.linkedinPage && formikErrors?.linkedinPage
                }
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />
            {!formikErrors?.linkedinPage && (
                <p className="OptionalTypoGraphy">Optional</p>
            )}
        </>
    )
}
EmployedPersonnelDetails.propTypes = {
    showCalendar: PropTypes.bool.isRequired,
    setShowCalendar: PropTypes.func.isRequired,
    employmentContract: PropTypes.string.isRequired,
    setEmploymentContract: PropTypes.func.isRequired,
    setShowUploadModal: PropTypes.func.isRequired,
    isAdminUser: PropTypes.bool,
    updateErrors: PropTypes.object,
    removeFromUpdateErrs: PropTypes.func,
    isAssistedKyc: PropTypes.bool,
}

export default EmployedPersonnelDetails
