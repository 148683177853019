import PropTypes from 'prop-types'
import receiptImg from 'src/assets/images/receipt-item.svg'
import s from './styles.module.scss'
import { creditReportFileName, membersListValues } from './utils'
import NairaSymbol from 'src/components/NairaSymbol'
import { ReactComponent as Dots } from 'src/assets/images/dots.svg'
import { Dropdown, Pagination } from 'antd'
import DropdownMenu from './components/DropdownMenu'
import { ReactComponent as WarningIcon } from 'src/assets/images/Circle_Warning.svg'
import { ReactComponent as AttachIcon } from 'src/assets/images/clip.svg'
import { truncateSentence } from 'src/utils/formatting'
import CustomToolTip from 'src/components/CustomToolTip'

const MembersList = ({
    pageAction,
    members,
    membersWithDuePayment,
    openMemberDetailsModal,
    storeActionAndOpenModal,
    totalPages,
    currentPage,
    updatePage,
    membersListFiltered,
    creditReportFiles,
    membersWithNoCreditReport,
    openFileUploadModal,
}) => {
    const {
        list,
        tableHeaders,
        isEmpty,
        emptyHeading,
        emptyDescription,
        rowClass,
    } = membersListValues(
        pageAction,
        members,
        membersWithDuePayment,
        membersListFiltered,
        membersWithNoCreditReport,
    )
    const Menu = row => (
        <Dropdown
            trigger={['click']}
            overlay={
                <DropdownMenu
                    openMemberDetailsModal={openMemberDetailsModal}
                    storeActionAndOpenModal={storeActionAndOpenModal}
                    status={row.member_status}
                    member={row}
                    pageAction={pageAction}
                    openFileUploadModal={openFileUploadModal}
                />
            }
            placement="bottomRight"
        >
            <a onClick={e => e.preventDefault()}>
                <div className={s.dotsWrapper}>
                    <Dots
                        role="button"
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </div>
            </a>
        </Dropdown>
    )

    const creditReportFile = row => (
        <>
            {creditReportFileName(creditReportFiles, row) ? (
                <div className={s.docName}>
                    <AttachIcon />
                    <span>
                        {row?.email_address}
                        .pdf
                    </span>
                </div>
            ) : (
                <CustomToolTip text="Document not found, ensure that the file name is the member's email address">
                    <p className={s.noDocumentFound}>
                        No Document Found
                        <span>
                            <WarningIcon />
                        </span>
                    </p>
                </CustomToolTip>
            )}
        </>
    )

    return (
        <div>
            {isEmpty ? (
                <div className={s.emptyState}>
                    <img src={receiptImg} alt="receipt" />
                    <h4>{emptyHeading}</h4>
                    <p>{emptyDescription}</p>
                </div>
            ) : (
                <div>
                    <div className={s.mobileTable}>
                        {list.map(row => (
                            <div key={row.id} className={s.mobileCard}>
                                <div className={s.nameAndOptions}>
                                    <p>{row?.name}</p>
                                    {Menu(row)}
                                </div>
                                <div className={s.emailAndStatus}>
                                    <div>
                                        <p className={s.title}>Email Address</p>
                                        <p className={s.emailAddress}>
                                            {truncateSentence(
                                                row?.email_address,
                                                12,
                                            )}
                                        </p>
                                    </div>
                                    {pageAction ===
                                    'upload-multiple-credit-report' ? (
                                        <div>
                                            <p
                                                className={s.title}
                                                style={{ textAlign: 'right' }}
                                            >
                                                Credit Report
                                            </p>

                                            {creditReportFile(row)}
                                        </div>
                                    ) : (
                                        <div>
                                            <p className={s.title}>Status</p>

                                            {row?.system_size?.value &&
                                            !row?.has_credit_report ? (
                                                <span
                                                    className={
                                                        s.noCreditReportTag
                                                    }
                                                >
                                                    NO CREDIT REPORT
                                                </span>
                                            ) : row?.due_payment ? (
                                                <span className={s.dueTag}>
                                                    due
                                                </span>
                                            ) : (
                                                <span
                                                    className={
                                                        row?.member_status ===
                                                        'Active'
                                                            ? s.statusActive
                                                            : s.statusInactive
                                                    }
                                                >
                                                    {row?.member_status}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <table className={s.table}>
                        <tr className={s.tableHead}>
                            {tableHeaders.map((header, i) => (
                                <th key={i}>{header}</th>
                            ))}
                        </tr>
                        {list?.map((row, i) => (
                            <tr key={i} className={s[rowClass]}>
                                <td>{row?.name}</td>
                                <td>
                                    {row?.location
                                        ? truncateSentence(row?.location, 12)
                                        : 'N/A'}
                                </td>
                                {pageAction === 'view-dashboard' && (
                                    <>
                                        <td>
                                            {row?.system_size
                                                ? `${row?.system_size?.value} ${row?.system_size?.unit_of_measurement}`
                                                : 'N/A'}
                                        </td>
                                        <td>
                                            {row?.monthly_payment ? (
                                                <>
                                                    <NairaSymbol />
                                                    {row?.monthly_payment}
                                                </>
                                            ) : (
                                                'N/A'
                                            )}
                                        </td>
                                        <td>
                                            {row?.system_size?.value &&
                                            !row?.has_credit_report ? (
                                                <span
                                                    className={
                                                        s.noCreditReportTag
                                                    }
                                                >
                                                    NO CREDIT REPORT
                                                </span>
                                            ) : row?.due_payment ? (
                                                <span className={s.dueTag}>
                                                    due
                                                </span>
                                            ) : (
                                                <span
                                                    className={
                                                        row?.member_status ===
                                                        'Active'
                                                            ? s.statusActive
                                                            : s.statusInactive
                                                    }
                                                >
                                                    {row?.member_status}
                                                </span>
                                            )}
                                        </td>
                                        <td>{Menu(row)}</td>
                                    </>
                                )}
                                {pageAction === 'make-payment' && (
                                    <>
                                        <td>{row?.email_address}</td>
                                        <td>
                                            <NairaSymbol />{' '}
                                            {row?.monthly_payment}
                                        </td>
                                        <td>
                                            <span className={s.dueTag}>
                                                due
                                            </span>
                                        </td>
                                    </>
                                )}
                                {pageAction ===
                                    'upload-multiple-credit-report' && (
                                    <>
                                        <td>{row?.email_address}</td>
                                        <td>
                                            {row?.identity_verification?.type}
                                        </td>
                                        <td>{creditReportFile(row)}</td>
                                        <td>{Menu(row)}</td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </table>
                </div>
            )}
            {totalPages > 1 && (
                <div className={s.paginationContainer}>
                    <Pagination
                        defaultCurrent={1}
                        current={currentPage}
                        total={parseInt(totalPages, 10)}
                        defaultPageSize={1}
                        showSizeChanger={false}
                        showTitle={false}
                        showLessItems
                        onChange={updatePage}
                    />
                </div>
            )}
        </div>
    )
}

MembersList.propTypes = {
    pageAction: PropTypes.string,
    members: PropTypes.array,
    membersWithDuePayment: PropTypes.array,
    openMemberDetailsModal: PropTypes.func,
    storeActionAndOpenModal: PropTypes.func,
    totalPages: PropTypes.number,
    currentPage: PropTypes.string,
    updatePage: PropTypes.func,
    membersListFiltered: PropTypes.bool,
    creditReportFiles: PropTypes.array,
    membersWithNoCreditReport: PropTypes.array,
    openFileUploadModal: PropTypes.func,
}

export default MembersList
