import Label from '../../../../PersonalInformation/_components/Label'
import { InputFields } from 'src/components/InputFields'
import { sharedFormComponentPropTypes } from '../utils'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'

const FullName = ({
    inputs,
    errors,
    handleChange,
    handleOnBlur,
    isAdminUser,
    isCustomerApproved,
}) => {
    return (
        <>
            <Label
                title={"What's your name?"}
                subTitle={
                    'Please provide your first name and surname as it appears on your ID card'
                }
            />
            <InputFields
                name="firstName"
                title="First Name"
                value={inputs.firstName}
                handleChange={handleChange}
                errorMessage={errors?.firstName}
                onBlur={handleOnBlur}
                disabled={isAdminUser || isCustomerApproved}
            />

            <InputFields
                name="lastName"
                title="Surname"
                value={inputs.lastName}
                handleChange={handleChange}
                errorMessage={errors?.lastName}
                onBlur={handleOnBlur}
                disabled={isAdminUser || isCustomerApproved}
            />

            <VerticalSpacer top={12} />
        </>
    )
}

FullName.propTypes = {
    ...sharedFormComponentPropTypes,
}

export default FullName
