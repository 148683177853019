import 'src/pages/Consumer/SmartShoppingExperience/CreateAccount/Verification/businessverification.scss'
import PropTypes from 'prop-types'
import s from './styles.module.scss'
import { videoGuide, sharedFormComponentPropTypes } from './utils'
import FullName from './components/FullName'
import ContactInfo from './components/ContactInfo'
import Address from './components/Address'
import EmploymentType from './components/EmploymentType'
import { decodeUserInfo } from 'src/utils/auth'
import WhereYouWork from './components/WhereYouWork'
import EmploymentDetails from './components/EmploymentDetails'
import EmployeeData from './components/EmployeeData'
import PensionDetails from './components/PensionDetails'
import BusinessDetails from './components/BusinessDetails'
import Avatar from './components/Avatar'
import IdCard from './components/IdCard'
import Bvn from './components/Bvn'
import RepaymentMethod from './components/RepaymentMethod'
import BankStatementSection from './components/BankStatementSection'
import CSSEVideoPlayer from 'src/components/CSSEVideoPlayer'
import { ReactComponent as PlayIcon } from 'src/assets/images/play-icon-green.svg'

const DynamicForm = ({
    kycModule,
    inputs,
    errors,
    handleChange,
    handleSelectChange,
    handleOnBlur,
    handleSelectOnBlur,
    handleSameAddressOptionChange,
    showRemoveFileModal,
    fileToBeRemoved,
    closeRemoveFileModal,
    closeRemoveFileModalAfterSuccess,
    showCalendar,
    setShowCalendar,
    setShowUploadModal,
    handleRemoveFile,
    employmentContract,
    setEmploymentContract,
    showUploadModal,
    handleCloseUploadModal,
    successEmploymentContractUpload,
    setSuccessEmploymentContractUpload,
    initiateContactVerification,
    initiateEmailVerificationLoading,
    initiatePhoneVerificationLoading,
    handleBusinessRegisteredChange,
    toggleShowCacVerifyModal,
    cacVerificationInProgress,
    isCustomerApproved,
    toggleSelfieImageModal,
    showIdOverrideModal,
    toggleIdOverrideModal,
    kycStatus,
    idVerificationInProgress,
    openIdVerifyModal,
    openBVNModal,
    bvnVerificationInProgress,
    kycData,
    shouldDisplayVideo,
    showVideoModal,
    setShowVideoModal,
    showVideo,
    setShowVideo,
    removeErrors,
    offer,
}) => {
    const userInfo = decodeUserInfo()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const componentMap = {
        full_name: FullName,
        phone_number_email: ContactInfo,
        address: Address,
        employment_type: EmploymentType,
        work_info: WhereYouWork,
        employment_details: EmploymentDetails,
        employee_data: EmployeeData,
        pension_details: PensionDetails,
        business: BusinessDetails,
        avatar: Avatar,
        id_card: IdCard,
        bvn: Bvn,
        repayment_method: RepaymentMethod,
        bank_statement: BankStatementSection,
    }

    const {
        showVideoGuide,
        videoGuideTitle,
        videoGuideHeader,
        videoGuideInfo,
        videoGuideUrl,
        videoGuideGIF,
        videoGuideDescription,
        videoGuidePageTitle,
    } = videoGuide(kycModule?.key, kycData)

    return (
        <>
            {(showVideoModal || showVideo) && showVideoGuide && (
                <CSSEVideoPlayer
                    showVideoModal={showVideoModal}
                    setShowVideoModal={setShowVideoModal}
                    showVideo={showVideo}
                    setShowVideo={setShowVideo}
                    modalTitle={videoGuideTitle}
                    header={videoGuideHeader}
                    videoInfo={videoGuideInfo}
                    videoUrl={videoGuideUrl}
                    videoGIF={videoGuideGIF}
                    description={videoGuideDescription}
                />
            )}

            {/* form */}
            <div className={s.formWrapper}>
                {shouldDisplayVideo && showVideoGuide && (
                    <div
                        className="FinancialFormVideoWrapper"
                        onClick={() => setShowVideoModal(true)}
                    >
                        <PlayIcon />
                        <p>{videoGuidePageTitle}</p>
                    </div>
                )}
                {kycModule?.section?.map((section, i) => {
                    if (section) {
                        const Component = componentMap[section.key]
                        return Component ? (
                            <Component
                                key={i}
                                section={section}
                                inputs={inputs}
                                errors={errors}
                                handleChange={handleChange}
                                handleSelectChange={handleSelectChange}
                                handleOnBlur={handleOnBlur}
                                handleSelectOnBlur={handleSelectOnBlur}
                                handleSameAddressOptionChange={
                                    handleSameAddressOptionChange
                                }
                                isAdminUser={isAdminUser}
                                showRemoveFileModal={showRemoveFileModal}
                                fileToBeRemoved={fileToBeRemoved}
                                closeRemoveFileModal={closeRemoveFileModal}
                                closeRemoveFileModalAfterSuccess={
                                    closeRemoveFileModalAfterSuccess
                                }
                                showCalendar={showCalendar}
                                setShowCalendar={setShowCalendar}
                                setShowUploadModal={setShowUploadModal}
                                handleRemoveFile={handleRemoveFile}
                                employmentContract={employmentContract}
                                setEmploymentContract={setEmploymentContract}
                                showUploadModal={showUploadModal}
                                handleCloseUploadModal={handleCloseUploadModal}
                                successEmploymentContractUpload={
                                    successEmploymentContractUpload
                                }
                                setSuccessEmploymentContractUpload={
                                    setSuccessEmploymentContractUpload
                                }
                                initiateContactVerification={
                                    initiateContactVerification
                                }
                                initiateEmailVerificationLoading={
                                    initiateEmailVerificationLoading
                                }
                                initiatePhoneVerificationLoading={
                                    initiatePhoneVerificationLoading
                                }
                                handleBusinessRegisteredChange={
                                    handleBusinessRegisteredChange
                                }
                                toggleShowCacVerifyModal={
                                    toggleShowCacVerifyModal
                                }
                                cacVerificationInProgress={
                                    cacVerificationInProgress
                                }
                                isCustomerApproved={isCustomerApproved}
                                toggleSelfieImageModal={toggleSelfieImageModal}
                                showIdOverrideModal={showIdOverrideModal}
                                toggleIdOverrideModal={toggleIdOverrideModal}
                                kycStatus={kycStatus}
                                idVerificationInProgress={
                                    idVerificationInProgress
                                }
                                openIdVerifyModal={openIdVerifyModal}
                                openBVNModal={openBVNModal}
                                bvnVerificationInProgress={
                                    bvnVerificationInProgress
                                }
                                kycData={kycData}
                                removeErrors={removeErrors}
                                offer={offer}
                            />
                        ) : null
                    }
                })}
            </div>
        </>
    )
}

DynamicForm.propTypes = {
    kycModule: PropTypes.object,
    ...sharedFormComponentPropTypes,
}

export default DynamicForm
