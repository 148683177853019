import PropTypes from 'prop-types'
import { useAppContext } from 'src/appContext/AppContext'
import LinkIcon from 'src/assets/images/link.svg'
import { InputFields, SelectField, TextArea } from 'src/components/InputFields'
import { IconInputField } from 'src/components/InputFields/CostField'
import VerticalSpacer from 'src/components/Spacer/VerticalSpacer'
import { businessIndustry, businessType } from 'src/utils/business'
import { cacNumberRegex } from 'src/utils/validationSchema'
import { usePersonalInfoContext } from '../../../../context'
import { isMobile } from 'src/utils/mediaQueries'
import { useSelfEmployedAdditionalForm } from './hooks/hooks'

const SelfEmployedPersonnelDetails = ({
    setVerificationModal,
    isAdminUser,
    updateErrors,
    removeFromUpdateErrs,
    verificationInProgress,
    isAssistedKyc,
}) => {
    const { isCustomerApproved } = useAppContext()
    const { employmentInfoFormik } = usePersonalInfoContext()
    const {
        values,
        errors,
        setValues,
        touched,
        setFieldTouched,
        setFieldValue,
        setFieldError,
        handleChange,
        handleBlur,
    } = employmentInfoFormik
    const { handleTracking, handleSelectChange } =
        useSelfEmployedAdditionalForm({ setFieldValue, removeFromUpdateErrs })
    const businessRegistrationConfirmed = values?.isBusinessRegistered === true
    const businessRegistrationChanged = values?.isBusinessRegistered === false

    return (
        <>
            <div className="RegisteredBusiness_Confirmation-Wrapper">
                <p>Is your business registered?</p>
                <div>
                    <button
                        onClick={() => {
                            setFieldTouched('cacNumber', false)
                            setValues(prev => ({
                                ...prev,
                                isBusinessRegistered: true,
                            }))
                        }}
                        style={{
                            color: businessRegistrationConfirmed
                                ? '#ffffff'
                                : '',
                            background: businessRegistrationConfirmed
                                ? '#011A3C'
                                : '',
                        }}
                        className="RegisteredBusiness_Confirmation-CTA"
                        disabled={
                            (!isAssistedKyc && isAdminUser) ||
                            isCustomerApproved
                        }
                    >
                        Yes, It is
                    </button>
                    <button
                        onClick={() => {
                            setValues(prev => ({
                                ...prev,
                                isBusinessRegistered: false,
                            }))
                        }}
                        style={{
                            color: businessRegistrationChanged ? '#ffffff' : '',
                            background: businessRegistrationChanged
                                ? '#011A3C'
                                : '',
                        }}
                        className="RegisteredBusiness_Confirmation-CTA"
                        disabled={
                            (!isAssistedKyc && isAdminUser) ||
                            isCustomerApproved
                        }
                    >
                        No, Its not
                    </button>
                </div>
                {errors?.isBusinessRegistered && (
                    <span className="fieldError">
                        {errors?.isBusinessRegistered}
                    </span>
                )}
            </div>
            <VerticalSpacer bottom="24px" />
            <InputFields
                title={`${
                    businessRegistrationConfirmed ? 'Registered ' : ''
                }Business Name`}
                name="businessName"
                value={values.businessName}
                handleChange={e => {
                    handleChange(e)
                    removeFromUpdateErrs('businessName')
                }}
                onBlur={e => {
                    handleBlur(e)
                    handleTracking(e)
                }}
                errorMessage={
                    updateErrors?.businessName ||
                    (touched.businessName &&
                        (errors?.businessName || errors?.businessNameVerified))
                }
                verified={values?.businessNameVerified}
                disabled={
                    (values?.businessNameVerified &&
                        businessRegistrationConfirmed) ||
                    (!isAssistedKyc && isAdminUser)
                }
            />
            <SelectField
                floatLabelOnSelectedValue
                floatingLabel="Business Type"
                name="businessType"
                initialOption="Business Type"
                selectWidth="100%"
                marginBottom="24px"
                values={businessType.map(option => ({
                    value: option,
                }))}
                value={values.businessType}
                errorMessage={
                    updateErrors?.businessType ||
                    (touched.businessType && errors?.businessType)
                }
                currentSelected={values.businessType}
                handleChange={(name, value) => {
                    handleSelectChange(name, value)
                }}
                onBlur={() => {
                    setFieldTouched('businessType', true)
                    if (!values.businessType)
                        setFieldError('businessType', errors.businessType)
                }}
                withCheckBox
                dropdownPositionRelative
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />
            <SelectField
                floatLabelOnSelectedValue
                floatingLabel="Business Industry"
                initialOption="Business Industry"
                name="businessIndustry"
                selectWidth="100%"
                marginBottom="24px"
                values={businessIndustry.map(option => ({
                    value: option,
                }))}
                value={values.businessIndustry}
                errorMessage={
                    updateErrors?.businessIndustry ||
                    (touched.businessIndustry && errors?.businessIndustry)
                }
                currentSelected={values.businessIndustry}
                handleChange={(name, value) => {
                    handleSelectChange(name, value)
                }}
                onBlur={() => {
                    setFieldTouched('businessIndustry', true)
                    if (!values.businessIndustry)
                        setFieldError(
                            'businessIndustry',
                            errors.businessIndustry,
                        )
                }}
                withCheckBox
                dropdownPositionRelative
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />
            {businessRegistrationConfirmed && (
                <div style={{ position: 'relative' }}>
                    <InputFields
                        title="CAC number"
                        name="cacNumber"
                        value={values.cacNumber}
                        handleChange={e => {
                            handleChange(e)
                            removeFromUpdateErrs('cacNumber')
                        }}
                        onBlur={e => {
                            handleBlur(e)
                            handleTracking(e)
                        }}
                        marginBottom="6px"
                        //TODO: replace state with api response to confirm verification
                        verified={values?.cacNumberVerified}
                        disabled={
                            (values?.cacNumberVerified &&
                                values?.businessNameVerified) ||
                            (!isAssistedKyc && isAdminUser) ||
                            isCustomerApproved ||
                            verificationInProgress
                        }
                        errorMessage={
                            updateErrors?.cacNumber ||
                            (touched.cacNumber && errors?.cacNumber) ||
                            (touched.cacNumber && errors?.cacNumberVerified) ||
                            ''
                        }
                        inprogress={verificationInProgress}
                    />
                    {verificationInProgress && (
                        <span className="verificationInProgressStyle">
                            Verification In Progress
                        </span>
                    )}
                    {(values?.cacNumberVerified === true &&
                        values?.businessNameVerified === true) ||
                    verificationInProgress ? null : (
                        <div className="VerifyCTA">
                            <button
                                onClick={() => {
                                    setVerificationModal(true)
                                    setValues({
                                        ...values,
                                        businessNameConfirmed:
                                            values.businessName,
                                        cacNumberConfirmed: values.cacNumber,
                                    })
                                }}
                                disabled={
                                    values?.businessName === '' ||
                                    (values?.cacNumberVerified &&
                                        values?.businessNameVerified) ||
                                    cacNumberRegex.test(values?.cacNumber) ===
                                        false ||
                                    (!isAssistedKyc && isAdminUser) ||
                                    isCustomerApproved
                                }
                            >
                                {isMobile ? 'Verify' : 'Verify your CAC number'}
                            </button>
                        </div>
                    )}
                </div>
            )}
            <VerticalSpacer bottom="24px" />
            <InputFields
                title="Number of years in operation"
                name="numberOfYearsInOperation"
                value={values.numberOfYearsInOperation}
                handleChange={e => {
                    handleChange(e)
                    removeFromUpdateErrs('numberOfYearsInOperation')
                }}
                onBlur={e => {
                    handleBlur(e)
                    handleTracking(e)
                }}
                errorMessage={
                    updateErrors?.numberOfYearsInOperation ||
                    (touched.numberOfYearsInOperation &&
                        errors?.numberOfYearsInOperation)
                }
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />

            <IconInputField
                icon={LinkIcon}
                name="website"
                title="Website"
                width={'100%'}
                value={values.website}
                handleChange={handleChange}
                onBlur={e => {
                    handleBlur(e)
                    handleTracking(e)
                }}
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
                errorMessage={touched.website && errors.website}
            />
            {!errors.website && <p className="OptionalTypoGraphy">Optional</p>}

            <TextArea
                title="Description of business"
                name="businessDescription"
                value={values.businessDescription}
                handleChange={e => {
                    handleChange(e)
                    removeFromUpdateErrs('businessDescription')
                }}
                onBlur={e => {
                    handleBlur(e)
                    handleTracking(e)
                }}
                errorMessage={
                    updateErrors?.businessDescription ||
                    (touched.businessDescription && errors?.businessDescription)
                }
                rows={5}
                height="162px"
                disabled={(!isAssistedKyc && isAdminUser) || isCustomerApproved}
            />
        </>
    )
}

SelfEmployedPersonnelDetails.propTypes = {
    setVerificationModal: PropTypes.func.isRequired,
    isAdminUser: PropTypes.bool,
    verificationInProgress: PropTypes.bool,
    updateErrors: PropTypes.object,
    removeFromUpdateErrs: PropTypes.func,
    isAssistedKyc: PropTypes.bool,
}

export default SelfEmployedPersonnelDetails
