import styles from '../solarsystemslistingsv2.module.scss'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Drawer } from 'antd'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ButtonComponent, { ConsumerButton } from 'src/components/Button'
import EmptyState from 'src/components/EmptyState'
import { SelectField } from 'src/components/InputFields'
import NairaSymbol from 'src/components/NairaSymbol'
import { SkeletalCards } from 'src/components/SkeletalCards'
import { currencyFormatter } from 'src/utils/currencyFormatter'
import fallbackImage from 'src/assets/images/fallback.svg'
import { ReactComponent as CloseIcon } from 'src/assets/images/close-icon-big.svg'
import infoIcon from 'src/assets/images/Info-warning-blue.svg'

import { Pagination } from 'antd'
import { sortByData } from '../utils'
import Filter from './Filter'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import { ReactComponent as FilterIcon } from 'src/assets/images/filter.svg'
import { isMobileOrTablet } from 'src/utils/mediaQueries'
import BuildCustomsModalContent from './BuildCustomsModalContent'
import RecommendationDrawer from './RecommendationDrawer'
import { useSolarSystemsContext } from '../hooks/useSolarSystems'
import CustomToolTip from 'src/components/CustomToolTip'
import { appTracking } from 'src/utils/appTracker'
import { consumerProfileEvents } from 'src/utils/mp-event-tracker/profile-and-all-solutions'
import { visitCount } from 'src/utils/visitCount'
import QuickViewSystem from '../../QuickViewSystem'

const Systems = () => {
    const {
        SystemsWrapper,
        SystemsListTopStyle,
        SystemsListTopStyle__btns,
        SolarSystemListWrapper,
        SystemsSortByWrapper,
        SystemsListBottomStyle,
        SolarSystemDescriptionStyle,
        SolarSystemPlanWrapper,
        SolarSystemNameStyle,
        SolarSystemNameWrapper,
        SolarSystemPriceWrapper,
        SolarSytemViewBtn,
        SystemsMainContentWrapper,
        FilterSortWrapper,
        FilterMobileWrapper,
        DrawerCloseIconWrapper,
        SolarSystemCardHR,
        DemandInfoWrapper,
    } = styles

    const pageTitle =
        'Solar systems | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = `SOLAR_SYSTEM_LISTING`

    const history = useHistory()
    {
        /* TODO: Remove this temp. till PM requires it */
    }

    const isAllSystems = window.location.pathname.includes('/all-systems')
    const isPromoUser = window.location.pathname.includes('/promo-detail')
    const {
        getEnergyProfileSolutionsData,
        getEnergyProfileSolutionsFetching,
        getEnergyProfileSolutionsFilterFetching,
        getEnergyProfileData,
        currentPage,
        setCurrentPage,
        sortBy,
        setSortBy,
        clearFilters,
        showBuildCustomModal,
        setShowBuildCustomModal,
        isClosedCluster,
        isPromo,
    } = useSolarSystemsContext()
    const isResidential =
        getEnergyProfileData?.data?.data?.customer_type == 'RESIDENTIAL'
    const count = getEnergyProfileSolutionsData?.data?.count
    const profileAppliances = getEnergyProfileData?.data?.data?.appliances
    const isPromoCluster =
        getEnergyProfileData?.data?.data?.promotional_type === 'PUBLIC' &&
        getEnergyProfileData?.data?.data?.is_promotional
    const isAuthenticatedUser = history.location?.state?.isAuthenticatedUser
    const auth_user_id = history.location?.state?.auth_user_id
    const estimation_id = history.location?.state?.estimation_id
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [showQuickView, setShowQuickView] = useState({
        open: false,
        data: {},
    })

    const [showRecommendationDrawer, setShowRecommendationDrawer] =
        useState(false)
    const handleSelectChange = (name, value) => {
        setSortBy(value)
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            )['useSort'].action,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            ).label,
            consumerProfileEvents(
                isAllSystems ? 'AASP' : !isResidential ? 'BP' : 'RP',
            ).category,
            ['MP', 'GA'],
            'event',
        )
    }

    const handleDrawer = () => {
        setDrawerVisible(true)
    }

    const closeDrawer = () => {
        setDrawerVisible(false)
        clearFilters()
    }

    const handleBuildCustomModal = () => {
        closeDrawer()
        setTimeout(() => {
            setShowBuildCustomModal({
                open: true,
                type: 'cancel',
            })
        }, 300)
    }

    const closeBuildCustomModal = () => {
        showBuildCustomModal.type === 'go-back'
            ? history.goBack()
            : setShowBuildCustomModal({
                  open: false,
                  type: 'cancel',
              })
    }

    // pagination
    const updatePage = page => {
        setCurrentPage(page)
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        isAllSystems &&
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                consumerProfileEvents('AAS')['viewListing'].action,
                consumerProfileEvents('AAS').label,
                consumerProfileEvents('AAS').category,
                ['MP', 'GA'],
                'event',
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <QuickViewSystem
                id={showQuickView?.data?.id}
                showQuickView={showQuickView}
                setShowQuickView={setShowQuickView}
                handleFullDetails={() => {
                    // TODO: Apply DRY to this in the subsequent no-jira to this
                    history.push({
                        pathname: `/consumer/view-system/${showQuickView?.data?.id}`,
                        state: {
                            profile_id: getEnergyProfileData?.data?.data?.id,
                            payment_model: showQuickView?.data?.payment_model,
                            appliances: showQuickView?.data?.fit_appliances,
                            isAllSystems,
                            onboardingType: 'profiles-or-all-systems',
                            isAuthenticatedUser,
                            auth_user_id,
                            estimation_id,
                            customer_type:
                                getEnergyProfileData?.data?.data?.customer_type,
                            isClosedCluster,
                            isPromoCluster,
                            isPromoUser,
                        },
                    })
                    visitCount(
                        showQuickView?.data?.id,
                        'system_details_visits',
                    ) > 0 &&
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            consumerProfileEvents(
                                `${visitCount(
                                    showQuickView?.data?.id,
                                    'system_details_visits',
                                )} ${
                                    isAllSystems
                                        ? 'AASPs'
                                        : !isResidential
                                        ? 'BPPs'
                                        : 'RPPs'
                                }`,
                            )['viewProfileCount'].action,
                            consumerProfileEvents(
                                `${visitCount(
                                    showQuickView?.data?.id,
                                    'system_details_visits',
                                )} ${
                                    isAllSystems
                                        ? 'AASPs'
                                        : !isResidential
                                        ? 'BPPs'
                                        : 'RPPs'
                                }`,
                            ).label,
                            consumerProfileEvents(
                                `${visitCount(
                                    showQuickView?.data?.id,
                                    'system_details_visits',
                                )} ${
                                    isAllSystems
                                        ? 'AASPs'
                                        : !isResidential
                                        ? 'BPPs'
                                        : 'RPPs'
                                }`,
                            ).category,
                            ['MP', 'GA'],
                            'event',
                            {
                                event: {
                                    name: showQuickView?.data.name,
                                    id: showQuickView?.data.id,
                                },
                            },
                        )
                }}
                profile_id={getEnergyProfileData?.data?.data?.id}
                payment_model={showQuickView?.data?.payment_model}
                appliances={showQuickView?.data?.fit_appliances}
                isAllSystems={isAllSystems}
                onboardingType={'profiles-or-all-systems'}
                estimation_id={estimation_id}
                customer_type={getEnergyProfileData?.data?.data?.customer_type}
                isClosedCluster={isClosedCluster}
                isPromoCluster={isPromoCluster}
                isPromoUser={isPromoUser}
            />

            <RecommendationDrawer
                showDrawer={showRecommendationDrawer}
                hideDrawer={() => setShowRecommendationDrawer(false)}
            />
            <section className={SystemsWrapper}>
                {count > 0 ? (
                    <div className={SystemsListTopStyle}>
                        <div className={SystemsListTopStyle__btns}>
                            {isAllSystems ? (
                                <p>There are {count} available systems</p>
                            ) : (
                                <p>
                                    {count} recommendations available for this
                                    {isPromo ? ' promo' : ' profile'}
                                </p>
                            )}
                            {/* TODO: Remove this temp. till PM requires it */}
                            {/* {powerCurveToggle ? (
                                <ConsumerButton
                                    btnBgColor="#F9FCFF"
                                    btnTextColor="#004AAD"
                                    borderOutlineColor="#004AAD"
                                    btnHeight="31px"
                                    borderRadius="30px"
                                    handleClick={() => {
                                        setShowRecommendationDrawer(true)
                                    }}
                                >
                                    Get an alternative recommendation
                                </ConsumerButton>
                            ) : null} */}
                        </div>
                        <div className={FilterSortWrapper}>
                            <button
                                className={FilterMobileWrapper}
                                role="button"
                                onClick={() => {
                                    handleDrawer()
                                }}
                            >
                                <FilterIcon role="presentation" />
                                <span>Filter</span>
                            </button>
                            <div className={SystemsSortByWrapper}>
                                <span>Sort by</span>
                                <SelectField
                                    name="sortBy"
                                    initialOption={sortBy}
                                    selectWidth="171px"
                                    handleChange={handleSelectChange}
                                    withCheckBox={true}
                                    dropdownPositionRelative
                                    type="SSEOffset"
                                    selectedValue={sortBy}
                                    currentSelected={sortBy}
                                    values={sortByData.map(option => ({
                                        value: option,
                                    }))}
                                    value={sortBy}
                                    optionStyle={{
                                        paddingTop: '15px',
                                        paddingBottom: '15px',
                                    }}
                                    disabled={
                                        getEnergyProfileSolutionsData?.data
                                            ?.data?.length === 0 ||
                                        getEnergyProfileSolutionsFetching
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className={SystemsMainContentWrapper}>
                    <div>
                        {!isMobileOrTablet && (
                            <Filter toggleModal={handleBuildCustomModal} />
                        )}
                    </div>
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        {getEnergyProfileSolutionsFetching ||
                        getEnergyProfileSolutionsFilterFetching ? (
                            <SkeletalCards total={10} />
                        ) : getEnergyProfileSolutionsData?.data?.data?.length >
                          0 ? (
                            <div className={SystemsListBottomStyle}>
                                {getEnergyProfileSolutionsData?.data?.data?.map(
                                    (system, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    SolarSystemListWrapper
                                                }
                                            >
                                                <LazyLoadImage
                                                    src={
                                                        system?.has_solar_panel
                                                            ? 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/With+panels+thumbnail.png'
                                                            : 'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Without+panels+thumbnail.png'
                                                    }
                                                    alt="solar-system-img"
                                                    placeholderSrc={
                                                        fallbackImage
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        borderBottom:
                                                            ' 1px solid #E2EEFF',
                                                    }}
                                                ></div>

                                                <div
                                                    style={{
                                                        padding: '20px',
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            SolarSystemNameWrapper
                                                        }
                                                    >
                                                        <p
                                                            className={
                                                                SolarSystemNameStyle
                                                            }
                                                        >
                                                            {system.name}
                                                        </p>
                                                    </div>
                                                    <p
                                                        className={
                                                            SolarSystemDescriptionStyle
                                                        }
                                                    >
                                                        {system.energy_capacity
                                                            ?.value +
                                                            system
                                                                .energy_capacity
                                                                ?.unit_of_measurement}{' '}
                                                        system
                                                    </p>
                                                    <div
                                                        className={
                                                            SolarSystemPlanWrapper
                                                        }
                                                    >
                                                        {system.payment_model ===
                                                            'Lease to Own' && (
                                                            <p>{`${
                                                                system.number_of_plans
                                                            } Plan${
                                                                system.number_of_plans ===
                                                                1
                                                                    ? ''
                                                                    : 's'
                                                            }`}</p>
                                                        )}
                                                        <p>
                                                            {
                                                                system.payment_model
                                                            }
                                                        </p>
                                                    </div>
                                                    <section
                                                        className={
                                                            SolarSystemPriceWrapper
                                                        }
                                                    >
                                                        {system.upfront_deposit &&
                                                            system.payment_model !==
                                                                'Outright Sale' && (
                                                                <div>
                                                                    <p>
                                                                        Upfront
                                                                        deposit
                                                                    </p>
                                                                    <p
                                                                        className={
                                                                            SolarSystemNameStyle
                                                                        }
                                                                    >
                                                                        <NairaSymbol />
                                                                        {currencyFormatter(
                                                                            system.upfront_deposit,
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            )}
                                                        <div>
                                                            <p>
                                                                {system.payment_model ===
                                                                'Outright Sale'
                                                                    ? 'Full price'
                                                                    : 'Min. monthly payment'}
                                                            </p>
                                                            <p
                                                                className={
                                                                    SolarSystemDescriptionStyle
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                }}
                                                            >
                                                                <NairaSymbol />
                                                                {system.payment_model ===
                                                                'Outright Sale'
                                                                    ? currencyFormatter(
                                                                          system.total_cost,
                                                                      )
                                                                    : currencyFormatter(
                                                                          system.least_payable_amount,
                                                                      )}
                                                            </p>
                                                        </div>
                                                    </section>
                                                    <hr
                                                        className={
                                                            SolarSystemCardHR
                                                        }
                                                    />
                                                    <section
                                                        className={
                                                            SolarSystemPriceWrapper
                                                        }
                                                    >
                                                        <div>
                                                            <p>
                                                                Backup duration
                                                            </p>
                                                            <p
                                                                className={
                                                                    SolarSystemNameStyle
                                                                }
                                                            >
                                                                {Math.round(
                                                                    system?.average_backup_hours,
                                                                )}{' '}
                                                                Hour
                                                                {system?.average_backup_hours >
                                                                1
                                                                    ? 's'
                                                                    : ''}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                Avg. load size
                                                            </p>
                                                            <div
                                                                className={
                                                                    DemandInfoWrapper
                                                                }
                                                            >
                                                                <p
                                                                    style={{
                                                                        textAlign:
                                                                            'right',
                                                                    }}
                                                                >
                                                                    {
                                                                        system?.consumer_avg_demand
                                                                    }
                                                                </p>
                                                                <CustomToolTip
                                                                    text={`${
                                                                        system?.consumer_avg_demand?.includes(
                                                                            'kVA',
                                                                        )
                                                                            ? ''
                                                                            : `${system?.consumer_avg_demand} also means ${system?.consumer_avg_demand_kva}. `
                                                                    }Keep total power use at the recommended level to get the listed backup hours.`}
                                                                    toolTipFontSize="12px"
                                                                    toolTipPadding="9px 12px 9px 8px"
                                                                    toolTipPopupWidth={
                                                                        174
                                                                    }
                                                                    toolTipMarginLeft={
                                                                        -150
                                                                    }
                                                                >
                                                                    <img
                                                                        src={
                                                                            infoIcon
                                                                        }
                                                                        alt={
                                                                            'info icon'
                                                                        }
                                                                        role={
                                                                            'presentation'
                                                                        }
                                                                    />
                                                                </CustomToolTip>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <div
                                                        className={
                                                            SolarSytemViewBtn
                                                        }
                                                    >
                                                        <ConsumerButton
                                                            btnHeight="53px"
                                                            btnWidth="100%"
                                                            btnBgColor={
                                                                '#004AAD'
                                                            }
                                                            btnTextColor={
                                                                '#FFFFFF'
                                                            }
                                                            btnFontSize="14px"
                                                            secondaryHover
                                                            handleClick={() => {
                                                                if (
                                                                    process.env
                                                                        .REACT_APP_FLAG_SHOW_SYSTEM_QUICK_VIEW ===
                                                                        'true' &&
                                                                    !isAuthenticatedUser
                                                                ) {
                                                                    setShowQuickView(
                                                                        {
                                                                            open: true,
                                                                            data: system,
                                                                        },
                                                                    )
                                                                } else {
                                                                    history.push(
                                                                        {
                                                                            pathname: `/consumer/view-system/${system?.id}`,
                                                                            state: {
                                                                                profile_id:
                                                                                    getEnergyProfileData
                                                                                        ?.data
                                                                                        ?.data
                                                                                        ?.id,
                                                                                payment_model:
                                                                                    system?.payment_model,
                                                                                appliances:
                                                                                    system?.fit_appliances,
                                                                                isAllSystems,
                                                                                onboardingType:
                                                                                    'profiles-or-all-systems',
                                                                                isAuthenticatedUser,
                                                                                auth_user_id,
                                                                                estimation_id,
                                                                                customer_type:
                                                                                    getEnergyProfileData
                                                                                        ?.data
                                                                                        ?.data
                                                                                        ?.customer_type,
                                                                                isClosedCluster,
                                                                                isPromoCluster,
                                                                                isPromoUser,
                                                                            },
                                                                        },
                                                                    )
                                                                    visitCount(
                                                                        system?.id,
                                                                        'system_details_visits',
                                                                    ) > 0 &&
                                                                        appTracking(
                                                                            pageUrlName,
                                                                            pageTracker,
                                                                            pageTitle,
                                                                            consumerProfileEvents(
                                                                                `${visitCount(
                                                                                    system?.id,
                                                                                    'system_details_visits',
                                                                                )} ${
                                                                                    isAllSystems
                                                                                        ? 'AASPs'
                                                                                        : !isResidential
                                                                                        ? 'BPPs'
                                                                                        : 'RPPs'
                                                                                }`,
                                                                            )[
                                                                                'viewProfileCount'
                                                                            ]
                                                                                .action,
                                                                            consumerProfileEvents(
                                                                                `${visitCount(
                                                                                    system?.id,
                                                                                    'system_details_visits',
                                                                                )} ${
                                                                                    isAllSystems
                                                                                        ? 'AASPs'
                                                                                        : !isResidential
                                                                                        ? 'BPPs'
                                                                                        : 'RPPs'
                                                                                }`,
                                                                            )
                                                                                .label,
                                                                            consumerProfileEvents(
                                                                                `${visitCount(
                                                                                    system?.id,
                                                                                    'system_details_visits',
                                                                                )} ${
                                                                                    isAllSystems
                                                                                        ? 'AASPs'
                                                                                        : !isResidential
                                                                                        ? 'BPPs'
                                                                                        : 'RPPs'
                                                                                }`,
                                                                            )
                                                                                .category,
                                                                            [
                                                                                'MP',
                                                                                'GA',
                                                                            ],
                                                                            'event',
                                                                            {
                                                                                event: {
                                                                                    name: system.name,
                                                                                    id: system.id,
                                                                                },
                                                                            },
                                                                        )
                                                                }
                                                            }}
                                                        >
                                                            Quick view
                                                        </ConsumerButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    },
                                )}
                            </div>
                        ) : (
                            <div className="emptyStateWrapper">
                                <EmptyState
                                    title="No recommendations"
                                    type="noActivity"
                                    subTitle="No recommendations yet"
                                />
                            </div>
                        )}

                        {getEnergyProfileSolutionsData?.data?.total_pages > 1 &&
                        !getEnergyProfileSolutionsFetching ? (
                            <div className="ACLPaginationWrapper">
                                <Pagination
                                    defaultCurrent={1}
                                    current={currentPage}
                                    total={parseInt(
                                        getEnergyProfileSolutionsData.data
                                            .total_pages,
                                        10,
                                    )}
                                    defaultPageSize={1}
                                    showSizeChanger={false}
                                    showTitle={false}
                                    showLessItems
                                    onChange={updatePage}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                {/* Filter drawer */}
                <Drawer
                    placement="right"
                    visible={drawerVisible}
                    onClose={() => closeDrawer()}
                    closable={false}
                >
                    <>
                        <div className={DrawerCloseIconWrapper}>
                            <CloseIcon
                                onClick={() => {
                                    closeDrawer()
                                }}
                            />
                        </div>
                        <Filter toggleModal={handleBuildCustomModal} />
                        <ButtonComponent
                            btnBgColor="#004AAD"
                            btnTextColor="#fff"
                            btnWidth={'100%'}
                            marginTop={'16px'}
                            handleClick={() => setDrawerVisible(false)}
                        >
                            Apply Filter
                        </ButtonComponent>
                    </>
                </Drawer>

                {/* Build your system modal */}

                <SecondaryModal
                    v2
                    showModal={showBuildCustomModal.open}
                    title="Build a customised system instead"
                    modalWidth={476}
                    showFooter
                    footerLeftContent="button"
                    secondaryBtnText={
                        showBuildCustomModal.type === 'cancel'
                            ? 'Cancel'
                            : 'Go back'
                    }
                    primaryBtnText="Build your system"
                    content={<BuildCustomsModalContent />}
                    onCancel={() => {
                        setShowBuildCustomModal({
                            open: false,
                            type: 'cancel',
                        })
                    }}
                    handleSecondaryBtnClick={() => {
                        closeBuildCustomModal()
                    }}
                    handlePrimaryBtnClick={() => {
                        history.push({
                            pathname: '/consumer/get-started',
                            state: {
                                buildCustomSystem: true,
                                appliances: profileAppliances,
                            },
                        })
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            consumerProfileEvents(
                                isAllSystems
                                    ? 'AASP'
                                    : isResidential
                                    ? 'RP'
                                    : 'BP',
                            )['selectBuildSytem'].action,
                            consumerProfileEvents(
                                isAllSystems
                                    ? 'AASP'
                                    : isResidential
                                    ? 'RP'
                                    : 'BP',
                            ).label,
                            consumerProfileEvents(
                                isAllSystems
                                    ? 'AASP'
                                    : isResidential
                                    ? 'RP'
                                    : 'BP',
                            ).category,
                            ['MP', 'GA'],
                            'event',
                        )
                    }}
                />
            </section>
        </>
    )
}

export default Systems
